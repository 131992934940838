var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product__purchaser" }, [
    _c("div", { staticClass: "product__price" }, [
      _vm.specifications || _vm.pdfUrl
        ? _c(
            "div",
            { staticClass: "product__specification" },
            [
              _vm.specifications
                ? _c("expandable-text", {
                    attrs: {
                      "description-text": _vm.specifications,
                      "btn-label-collapsed": _vm._translate(
                        "Product specifications"
                      ),
                      "btn-label-expanded": _vm._translate(
                        "Hide product specifications"
                      ),
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.scrollListener.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.pdfUrl
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "1rem",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "white-link",
                          attrs: { href: _vm.pdfUrl, target: "_blank" },
                        },
                        [_vm._v(_vm._s(_vm._translate("Installation manual")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "customize",
        staticClass: "product__customize-title",
        class: {
          "product__customize-title-with-promo":
            _vm.promo.hasDiscount && _vm.promo.custom,
        },
      },
      [_vm._v(_vm._s(_vm.computedCustomize) + "\n    ")]
    ),
    _vm._v(" "),
    _vm.promo.hasDiscount && _vm.promo.custom
      ? _c(
          "p",
          {
            staticClass:
              "product__promo-description product__promo-description-custom",
          },
          [_vm._v("\n      " + _vm._s(_vm.computedPromoCustomText) + "\n    ")]
        )
      : _vm._e(),
    _vm.configurations
      ? _c(
          "form",
          {
            ref: "form",
            staticClass: "product__form",
            attrs: { method: "POST", "accept-charset": "UTF-8" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.updateCart.apply(null, arguments)
              },
            },
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "action",
                value: "commerce/cart/update-cart",
              },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "purchasables[" + _vm.product.id + "][id]",
                id: "inputPurchasableId",
              },
              domProps: { value: _vm.currentVariant.id },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "purchasables[" + _vm.product.id + "][qty]",
              },
              domProps: { value: 1 },
            }),
            _vm._v(" "),
            _vm.product.type.handle === "main"
              ? _c("product-length", {
                  attrs: {
                    configurations: _vm.configurations,
                    "is-customize": _vm.isCustomize,
                  },
                  on: {
                    changeAdditionInfo: _vm.changeAdditionInfo,
                    costChange: _vm.costChange,
                    customLengthCorrect: _vm.setCustomLengthCorrect,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "product-expand",
              {
                attrs: {
                  title: _vm.colorTitle,
                  expanded:
                    _vm.product.type.handle === "newAccessories" ||
                    _vm.selectedOptions.v_color.vSkuModificator === "CC",
                  "custom-text":
                    _vm.selectedOptions.v_color.vSkuModificator === "CC"
                      ? _vm.store.state.additionInfo.v_color
                      : undefined,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.scrollListener.apply(null, arguments)
                  },
                },
              },
              [
                _c("product-colors", {
                  attrs: {
                    "init-color": _vm.color,
                    colors: _vm.colors,
                    product: _vm.product,
                    "sample-pack-url": _vm.samplePackUrl,
                  },
                  on: {
                    changeAdditionInfo: _vm.changeAdditionInfo,
                    costChange: _vm.costChange,
                  },
                }),
                _vm._v(" "),
                (_vm.product.type.handle === "main" ||
                  _vm.product.type.handle === "newAccessories") &&
                _vm.filters.v_finish &&
                _vm.filters.v_finish.length &&
                _vm.isFinishAvailable
                  ? _c("product-finish", {
                      attrs: {
                        header: _vm._translate("Finish"),
                        items: _vm.store.state.filters.v_finish,
                      },
                      on: { costChange: _vm.costChange },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.descriptions.vfinish
                  ? _c("more-information", {
                      attrs: {
                        title: _vm._translate("Finish"),
                        description: _vm.descriptions.vfinish,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.product.type.handle === "main" &&
            _vm.filters.v_led &&
            _vm.filters.v_led.length
              ? _c(
                  "product-expand",
                  {
                    attrs: {
                      title: _vm._translate("Lighting"),
                      expanded: _vm.isExpanded("v_led"),
                      notification: _vm._translate(
                        "From 50 long and 22 cm deep"
                      ),
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.scrollListener.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("product-lightning", {
                      attrs: {
                        product: _vm.product,
                        disabled: _vm.disabled.v_led,
                        "disable-all-around": _vm.product.id === 60505,
                      },
                      on: { costChange: _vm.costChange },
                    }),
                    _vm._v(" "),
                    _vm.descriptions.vled
                      ? _c("more-information", {
                          attrs: {
                            title: _vm._translate("Lighting"),
                            description: _vm.descriptions.vled,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.product.type.handle === "main" &&
            _vm.filters.v_cutouts &&
            _vm.cutouts.length > 0
              ? _c(
                  "product-expand",
                  {
                    attrs: {
                      title: _vm._translate("Add-ons"),
                      expanded: _vm.isExpanded("v_cutouts"),
                      notification: _vm.computedCutoutsNotification,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.scrollListener.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("product-cut-outs", {
                      attrs: {
                        disabled: _vm.disabled.v_cutouts,
                        items: _vm.cutouts,
                      },
                      on: { costChange: _vm.costChange },
                    }),
                    _vm._v(" "),
                    _vm.descriptions.vcutOuts
                      ? _c("more-information", {
                          attrs: {
                            title: _vm._translate("Add-ons"),
                            description: _vm.descriptions.vcutOuts,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.product.type.handle === "main" &&
            _vm.filters.v_corners &&
            _vm.filters.v_corners.length
              ? _c(
                  "product-expand",
                  {
                    attrs: { title: _vm._translate("Corners") },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.scrollListener.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("product-corners", {
                      attrs: { items: _vm.store.state.filters.v_corners },
                      on: { costChange: _vm.costChange },
                    }),
                    _vm._v(" "),
                    _vm.descriptions.vcorners
                      ? _c("more-information", {
                          attrs: {
                            title: _vm._translate("Corners"),
                            description: _vm.descriptions.vcorners,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSamplesProductsInTheCart
              ? _c("div", { staticClass: "note-warning" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._translate(
                            "Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order."
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "button",
                staticClass:
                  "cf w-100 product__add-to-cart pointer input-reset f5 ls-05 bw0 bg-yellow sans-serif fw7 pa3 pa4-xl dib outline-0 br1 dim mb4",
                class: { "product__add-to-cart_absolute": _vm.isLoaded },
                attrs: { disabled: _vm.isButtonDisabled },
              },
              [
                _c("span", { staticClass: "fl w-50 tl" }, [
                  _vm._v(_vm._s(_vm._translate("Add to cart"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "fr" }, [
                  _vm._v(_vm._s(_vm._f("toEur")(_vm.computedCost))),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }