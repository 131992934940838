var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "color-samples-item",
      class: { "variant-disabled": _vm.isDisabled },
      on: { chage: _vm.carrySelectedSamples },
    },
    [
      _c(
        "div",
        {
          staticClass: "color-samples-item-color",
          style: [
            this.isSemiGlassMaterial
              ? {
                  "background-image":
                    "linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)",
                  backgroundColor: this.bgColor,
                }
              : { backgroundColor: this.bgColor },
          ],
        },
        [
          _c(
            "span",
            {
              staticClass:
                "flex justify-center items-center color-samples-item-selected",
              class: {
                "variant-selected": this.isSelected,
                black: _vm.isLightBg,
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._translate("Selected")) +
                  "\n              "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-between" }, [
        _c("div", { staticClass: "flex flex-column" }, [
          _c("div", { staticClass: "f6 color-samples-item-name" }, [
            _vm._v(_vm._s(_vm.variant.title)),
          ]),
          _vm._v(" "),
          _vm.variant.finishType
            ? _c("div", { staticClass: "f6 grey" }, [
                _vm._v(_vm._s(_vm.variant.finishType.title)),
              ])
            : _vm.variant.colorCode
            ? _c("div", { staticClass: "f6 grey" }, [
                _vm._v(_vm._s(_vm.variant.colorCode)),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "color-samples-item-checkbox" }, [
          _vm._v(_vm._s(_vm._translate("Add")) + "\n                      "),
          _c("input", {
            attrs: {
              type: "checkbox",
              name: "selected_color_sample",
              disabled: this.isDisabled,
            },
            domProps: { checked: this.isSelected },
            on: { change: _vm.carrySelectedSamples },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
        ]),
      ]),
      _vm._v(" "),
      _vm.variant.note
        ? _c("span", { staticClass: "f6 grey font-italic" }, [
            _vm._v(_vm._s(_vm.variant.note)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }