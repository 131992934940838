var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm._store.state.showMenuOverlay || !_vm._store.isMobile()
    ? _c(
        "div",
        {
          staticClass:
            "bg-black ph3 ph5-l pv3 tc pointer-events-auto z-max relative dn",
          class: { db: _vm.isVisible },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c("p", {
              staticClass: "sans-serif fw7 white infobar__text",
              domProps: { innerHTML: _vm._s(_vm._html) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "absolute top-0 right-0" }, [
              _c(
                "button",
                {
                  staticClass:
                    "bg-transparent pointer bw0 outline-0 pointer z-3 white o-50 glow",
                  attrs: { "aria-label": "close" },
                  on: { click: _vm.dismiss },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "v-mid",
                      staticStyle: { fill: "currentColor" },
                      attrs: { width: "12", height: "12" },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }