var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb3 side-item" },
    [
      _c(
        "transition",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        [
          _c("div", { staticClass: "flex mb3" }, [
            _c("div", { staticClass: "w-80" }, [
              _c("p", [_vm._v(_vm._s(_vm.variant.title))]),
              _vm._v(" "),
              _c("p", { staticClass: "grey" }, [
                _vm._v(
                  _vm._s(
                    _vm.variant.finishType
                      ? _vm.variant.finishType.title
                      : _vm.variant.colorCode
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-20" }, [
              _c("div", {
                staticClass: "color-square",
                style: [
                  _vm.variant.finishType !== null &&
                  _vm.variant.finishType.id === 66314
                    ? {
                        "background-image":
                          "linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)",
                        backgroundColor: _vm.variant.sampleColor.rgb,
                      }
                    : { background: _vm.variant.sampleColor.rgb },
                ],
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }