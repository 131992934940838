var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.outsideToggle,
          expression: "outsideToggle",
        },
      ],
      staticClass: "select__wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "select__handler", on: { click: _vm.selectToggle } },
        [
          _c("div", { staticClass: "select__title" }, [
            _vm._v(_vm._s(_vm.computedTitle)),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isShown
        ? _c(
            "div",
            { staticClass: "select__select" },
            _vm._l(_vm.computedColors, function (color) {
              return _c(
                "div",
                {
                  key: color.vSkuModificator,
                  staticClass: "select__color",
                  class: `select__color_${color.slug}`,
                  style: { background: color.color },
                  on: {
                    click: function ($event) {
                      return _vm.changeColor(color)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(color.colorCode))]),
                  _vm._v(" "),
                  color.isSelected
                    ? _c("span", { staticClass: "select__color-info" }, [
                        _vm._v(_vm._s(_vm._translate("Same color as shelf"))),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }