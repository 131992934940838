var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-samples-pack mb5" },
    [
      _vm._l(_vm.samples, function (item) {
        return _c("side-cart-item-sample", {
          key: item.id,
          attrs: {
            id: item.id,
            "purchasable-id": item.purchasableId,
            color: item.color,
            options: item.options,
            optionsSignature: item.optionsSignature,
            qty: item.qty,
            price: item.price,
            salePrice: item.salePrice,
            variant: item.variant,
          },
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-end custom-color-samples-buttons" },
        [
          _c(
            "form",
            {
              ref: "form",
              attrs: { method: "post", action: "", "accept-charset": "UTF-8" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updateQty.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "action",
                  value: "commerce/cart/update-cart",
                },
              }),
              _vm._v(" "),
              _vm._l(_vm.samples, function (item) {
                return _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "lineItems[" + item.id + "][id]",
                  },
                  domProps: { value: item.id },
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.samples, function (item) {
                return _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "lineItems[" + item.id + "][qty]",
                  },
                  domProps: { value: 0 },
                })
              }),
              _vm._v(" "),
              _c("button", [_vm._v(_vm._s(_vm._translate("Remove")))]),
            ],
            2
          ),
          _vm._v(" "),
          _c("span", [_vm._v("€" + _vm._s(_vm.totalCustomSamplesPrice))]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }