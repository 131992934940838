var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-form__wrapper" }, [
    _c(
      "form",
      { staticClass: "login-form", attrs: { method: "post" } },
      [
        _c("input", {
          attrs: { type: "hidden", name: "redirect" },
          domProps: { value: _vm.checkoutUrl },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "action", value: "users/login" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: _vm.csrfParam },
          domProps: { value: _vm.csrfToken },
        }),
        _vm._v(" "),
        _c(
          "input-component",
          {
            staticClass: "login-form__input",
            attrs: {
              name: "loginName",
              type: "text",
              required: true,
              invalid: _vm.$v.email.$invalid && _vm.$v.email.$dirty,
              success: !_vm.$v.email.$invalid && _vm.$v.email.$dirty,
              placeholder: _vm._translate("Email address"),
            },
            model: {
              value: _vm.$v.email.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.email, "$model", $$v)
              },
              expression: "$v.email.$model",
            },
          },
          [
            !_vm.$v.email.required && _vm.$v.email.$dirty
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._translate("This field is required")) +
                      "\n      "
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.email.email && _vm.$v.email.$dirty
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._translate("Email is invalid")) +
                      "\n      "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "input-component",
          {
            staticClass: "login-form__input",
            attrs: {
              name: "password",
              type: "password",
              required: true,
              invalid: _vm.$v.password.$invalid && _vm.$v.password.$dirty,
              success: !_vm.$v.password.$invalid && _vm.$v.password.$dirty,
              placeholder: _vm._translate("Password"),
            },
            model: {
              value: _vm.$v.password.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.password, "$model", $$v)
              },
              expression: "$v.password.$model",
            },
          },
          [
            !_vm.$v.password.required && _vm.$v.password.$dirty
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._translate("This field is required")) +
                      "\n      "
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.password.minLength && _vm.$v.password.$dirty
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._translate("Minimal field length:")) +
                      " " +
                      _vm._s(_vm.$v.password.$params.minLength.min) +
                      "\n      "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "login-form__actions" }, [
          _c(
            "button",
            {
              staticClass: "login-form__submit",
              attrs: { type: "submit", disabled: _vm.$v.$invalid },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm._translate("Log in")) + "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "login-form__forgot-link",
              attrs: { href: _vm.forgotUrl },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._translate("Forgot your password?")) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }