var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: {
        method: "post",
        "data-vv-scope": _vm.scope,
        "accept-charset": "UTF-8",
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", null, {
        accountType: _vm.accountType,
        isPartner: _vm.isPartner,
        setAccountType: _vm.setAccountType,
        componentKey: _vm.componentKey,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }