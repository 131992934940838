<template>
  <div class="product__cutout-option">
    <expand-horizontal-option :class="{
                                'expand__option_active': isShow,
                                'expand__option_disabled': disabled && !isShow,
                              }"
                              :title="option.title"
                              :info="option.vText"
                              :image="cover"
                              :cost="option.vPriceModificator"
                              @click.native="toggle"/>
    <div v-if="isShow && (Object.keys(availableSlots).length || Object.keys(option.vAvailPosition).length)"
         class="product__cutout-wrapper">
      <div v-if="Object.keys(availableSlots).length"
           class="product__cutout-section">
        <div class="product__cutout-section-count">
          <div v-for="(variants, cutType) in availableSlots">
            <div class="product__cutout-section-title">{{ _translate('Amount slots') }} {{ _translate(cutType) }}</div>
            <div class="product__cutout-select product__select"
                 tabindex="1">{{ additionInfo.slots[cutType] }}
              <div class="product__cutout-variants product__variants">
                <div v-for="(count, i) in variants"
                     :class="{'product__cutout-variant_active': additionInfo.slots[cutType] === count}"
                     class="product__cutout-variant"
                     @click="changeSlots(cutType, count)">{{ count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(option.vAvailPosition).length"
           class="product__cutout-section">
        <div class="product__cutout-section-title">{{ _translate('Position') }}</div>
        <div class="product__cutout-select-positions"
             :class="{'product__cutout-select-positions_full': Object.keys(availableSlots).length>1}">
          <expand-horizontal-option v-for="(position, i) in option.vAvailPosition"
                                    :key="i"
                                    :title="translateSentence(cutoutTitle(position.label))"
                                    :image="positionImage(position.label)"
                                    image-size="42% auto"
                                    :cutout="true"
                                    :short="true"
                                    class="product__cutout-select-position f7"
                                    :class="{
                                        'product__cutout-select-position_left': position.label === 'Left',
                                        'product__cutout-select-position_full': Object.keys(availableSlots).length>1,
                                        'product__cutout-select-position_active': translateSentence(cutoutTitle(position.label)) === translateSentence(additionInfo.position),
                                      }"
                                    @click.native="changePosition(position.label)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpandHorizontalOption from './ExpandHorizontalOption'
import store from '../store'
import Vue from 'vue'

export default {
  name: 'ProductCutOutOption',
  components: {
    ExpandHorizontalOption,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      state: store.state,
      additionInfo: { slots: {}, position: null },
      selectedPosition: 0,
    }
  },
  mounted() {
    if (this.isShow && this.disabled) {
      this.selectAddon()
    }
  },
  beforeMount () {
    const posKeys = Object.keys(this.option.vAvailPosition)
    if (posKeys.length && !this.additionInfo.position) {
      this.changePosition(this.option.vAvailPosition[posKeys[0]].label)
    }

    let defaultSlotsAmount = 1;
    if (this.option.vDefaultSlotsAmount) {
      defaultSlotsAmount = this.option.vDefaultSlotsAmount;
    }

    Object.keys(this.option.vAvailCutItems).map(key =>
        Vue.set(this.additionInfo.slots, this.option.vAvailCutItems[key].value, defaultSlotsAmount)
    )
  },
  computed: {
    cover() {
      if (this.option.cover && this.option.cover[0]) return this.option.cover[0];
      return '';
    },
    isShow () {
      return store.state.selectedOptions.hasOwnProperty('v_cutouts')
          && store.getSelectedOption('v_cutouts').id === this.option.id
    },
    availableSlots () {
      let slots = {}
      const cutTypes = Object.keys(this.option.vAvailCutItems)
      const lenPart = store.getSelectedLengthVal() / 2
      for (let i = 0; i < cutTypes.length; i++) {
        let variants = []
        let cutType = this.option.vAvailCutItems[cutTypes[i]].value
        let maxDishesSlots = Math.floor(lenPart / 7.5);
        let maxGlassSlots = Math.floor(lenPart / 12);
        switch (cutType) {
          case 'plates': {
            for (let s = 1; s <= maxDishesSlots; s++) {
              variants.push(s)
            }
            break
          }
          case 'glasses': {
            for (let s = 1; s <= maxGlassSlots; s++) {
              variants.push(s)
            }
            break
          }
        }
        if (variants.length) slots[cutType] = variants
      }
      return slots
    }
  },
  methods: {
    selectAddon() {
      store.setSelectedOption('v_cutouts', this.option)
      if (Object.keys(this.additionInfo.slots).length && this.additionInfo.position) {
        store.setAdditionInfo('v_cutouts', this.additionInfo)
      } else {
        store.setAdditionInfo('v_cutouts', null)
      }
    },
    positionImage(position){
      if(this.option['vImage'+position] && this.option['vImage'+position][0]) return this.option['vImage'+position][0];
      return '';
    },
    _translate (key) { return store._translates(key)},
    changePosition (position) {
      this.additionInfo.position = this.cutoutTitle(position)
    },
    translateSentence (text) {
       return text.split(' ').map(word => this._translate(word)).join(' ');
    },
    toggle () {
      if (!this.disabled) {
        this.selectAddon()
      }
    },
    changeSlots (type, value) {
      this.additionInfo.slots[type] = value
      document.activeElement.blur()
    },
    cutoutTitle (position) {
      let availableCutTypes = [];
      let platesAndGlasses = [];
      const cutTypes = Object.keys(this.option.vAvailCutItems)
      for (let i = 0; i < cutTypes.length; i++) {
        let cutOutName = this.option.vAvailCutItems[cutTypes[i]].value;
        switch (cutOutName) {
          case 'plates':
            platesAndGlasses.push(cutOutName);
            break;
          case 'glasses':
            platesAndGlasses.push(cutOutName)
            break;
        }

        availableCutTypes.push(cutOutName)
      }

      let title = position + ' ' + availableCutTypes.join(' & ');

      if (2 === platesAndGlasses.length) {
        let glasses = availableCutTypes[0];
        let plates = availableCutTypes[1];

        if (position === 'Left') {
          title = `${position} ${plates} & Right ${glasses}`;
        } else {
          title = `${position} ${plates} & Left ${glasses}`;
        }
      }

      return title
    },
  },
}
</script>

<style scoped lang="scss">
.product {
  &__cutout {
    &-actions {
      display: flex;
      justify-content: space-between;
    }

    &-select-positions {
      height: 80px;
      display: flex;
      gap: 1.5rem;

      &_full {
        flex-direction: column;
        height: auto;
      }
    }

    &-select-position {
      flex-basis: 50%;

      &_full {
        flex-basis: initial;
      }

      &_left {
        background-position: center right 0 !important;
        justify-content: flex-start;
      }

      &:hover {
        border-color: #616161;

        ::v-deep {
          .expand__option-title {
            color: #fff !important;
          }
        }
      }

      &_active {
        border-color: #ffd600;

        &:hover {
          border-color: #ffd600;
        }

        ::v-deep {
          .expand__option-title {
            color: #fff !important;
          }
        }

      }
    }

    &-variants {
      max-height: 250px;
      overflow-y: auto;
      padding: 8px 16px;
      border-radius: 2px;
      box-shadow: 0 0 24px 0 #000000;
      background-color: #333333;
      position: absolute;
      margin-top: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      left: 0;
      z-index: 2;
    }

    &-variant {
      color: #fff;
      margin: 8px 0;

      &_active,
      &:hover {
        color: #ffffff;
      }
    }

    &-select {
      padding: 12px 16px;
      border-radius: 2px;
      border: solid 2px #333;
      cursor: pointer;
      position: relative;
      display: block;
      color: #fff;
      outline: none;
      font-family: "FuturaPT", sans-serif;
      line-height: 1.5;
      transition: 0.3s;
      &:hover, &:focus {
        border-color: #616161;
      }
    }

    &-section {
      &-count {
        display: flex;
        gap: 24px;
        margin-bottom: 1rem;

        div {
          flex-basis: 100%;
        }
      }

      &-title {
        margin: 0 0 1rem;
        padding-top: 1rem;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 1rem;
    }

    &-wrapper {
      padding: 1rem 0 2rem;
      border-bottom: 1px solid #333333;
      margin-bottom: 1rem;
    }
  }
}
</style>
