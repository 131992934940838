var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-samples-products", attrs: { id: "color-samples" } },
    _vm._l(_vm.filteredProducts, function (variants, colorName) {
      return variants.length > 0
        ? _c("div", { staticClass: "color-samples-variants" }, [
            _c("h3", { staticClass: "color-samples-variants-title serif" }, [
              _vm._v(_vm._s(_vm._translate(colorName))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "color-samples-variants-list" },
              _vm._l(variants, function (variant) {
                return _c("color-samples-variant", {
                  attrs: { variant: variant },
                })
              }),
              1
            ),
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }