var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { css: false }, on: { enter: _vm.enter } }, [
    _vm.sharedState.faq
      ? _c("div", { staticClass: "grid cf" }, [
          _c("div", { staticClass: "mb5 mb6-l col col-12 col-2-l" }, [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.sharedState.faq, function (category, i) {
                return _c("li", { key: i, staticClass: "mb2 mb3-l" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "f5 f4-l serif white lh-title glow bg-transparent tl bn pointer outline-0",
                      class: [
                        i === _vm.privateState.activeCategory
                          ? "o-100"
                          : "o-50",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.setCategory(i)
                        },
                      },
                    },
                    [_vm._v(_vm._s(category.title) + "\n          ")]
                  ),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb5 mb6-l col col-12 col-4-l push-1-l" }, [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(
                _vm.sharedState.faq[_vm.privateState.activeCategory].questions,
                function (question, i) {
                  return _c("li", { key: i, staticClass: "mb2 mb3-l" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "f5 f4-l serif white lh-title glow bg-transparent tl bn pointer outline-0",
                        class: [
                          i === _vm.privateState.activeQuestion
                            ? "o-100"
                            : "o-50",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.setQuestion(i)
                          },
                        },
                      },
                      [_vm._v(_vm._s(question.title) + "\n          ")]
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb5 mb6-l col col-12 col-4-l push-1-l last nt1" },
            [
              _c("p", {
                staticClass: "mb4 sans-serif f4",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.sharedState.faq[_vm.privateState.activeCategory]
                      .questions[_vm.privateState.activeQuestion].text
                  ),
                },
              }),
              _vm._v(" "),
              _vm.sharedState.faq[_vm.privateState.activeCategory].questions[
                _vm.privateState.activeQuestion
              ].button
                ? _c(
                    "a",
                    {
                      staticClass:
                        "f5 ls-05 bw0 bg-yellow black-link-btn sans-serif black fw7 pv2 ph3 dib outline-0 mb2 br1 dim",
                      attrs: {
                        href: _vm.sharedState.faq[
                          _vm.privateState.activeCategory
                        ].questions[_vm.privateState.activeQuestion].button.url,
                        title:
                          _vm.sharedState.faq[_vm.privateState.activeCategory]
                            .questions[_vm.privateState.activeQuestion].button
                            .title,
                        target:
                          _vm.sharedState.faq[_vm.privateState.activeCategory]
                            .questions[_vm.privateState.activeQuestion].button
                            .target,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.sharedState.faq[_vm.privateState.activeCategory]
                            .questions[_vm.privateState.activeQuestion].button
                            .title
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }