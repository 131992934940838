var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "voucher__wrapper" }, [
    !_vm.isShowed
      ? _c(
          "span",
          { staticClass: "voucher__button", on: { click: _vm.toggleVoucher } },
          [_vm._v("\n    " + _vm._s(_vm._translate("Voucher Code")) + "?\n  ")]
        )
      : _c(
          "form",
          {
            ref: "form",
            attrs: { method: "post", "accept-charset": "UTF-8" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "action",
                value: "commerce/cart/update-cart",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "voucher__row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selfCoupon,
                    expression: "selfCoupon",
                  },
                ],
                staticClass: "voucher__input",
                attrs: {
                  type: "text",
                  name: "couponCode",
                  readonly: _vm.isLoading,
                  placeholder: _vm._translate("Voucher Code"),
                },
                domProps: { value: _vm.selfCoupon },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.selfCoupon = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "voucher__button",
                  class: { voucher__button_loading: _vm.isLoading },
                  attrs: {
                    disabled: !_vm.selfCoupon || _vm.isLoading,
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm._translate("Apply")) + "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.isApplied
              ? _c("div", { staticClass: "voucher__message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._translate("Voucher applied")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isNotFound
              ? _c(
                  "div",
                  { staticClass: "voucher__message voucher__message_warning" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._translate("Voucher is not found")) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }