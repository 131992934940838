var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addresses-form__radio-buttons" }, [
    !_vm.showBusinessOnly
      ? _c(
          "div",
          { staticClass: "addresses-form__radio-button" },
          [
            _c("radio-button", {
              attrs: { title: "Private", value: true, name: "isForBusiness" },
              model: {
                value: _vm.isPrivate,
                callback: function ($$v) {
                  _vm.isPrivate = $$v
                },
                expression: "isPrivate",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "addresses-form__radio-button",
        attrs: { id: "optionBusiness" },
      },
      [
        _c("radio-button", {
          attrs: { title: "For business", value: false, name: "isForBusiness" },
          model: {
            value: _vm.isPrivate,
            callback: function ($$v) {
              _vm.isPrivate = $$v
            },
            expression: "isPrivate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }