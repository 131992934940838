import { render, staticRenderFns } from "./ColorSamples.vue?vue&type=template&id=44f5c722&scoped=true&class=color-samples-pack"
import script from "./ColorSamples.vue?vue&type=script&lang=js"
export * from "./ColorSamples.vue?vue&type=script&lang=js"
import style0 from "./ColorSamples.vue?vue&type=style&index=0&id=44f5c722&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f5c722",
  null
  
)

export default component.exports