<template>
  <color-radio-button v-bind="{color, value, modelValue, infoText}" :isMobile="isMobile" :isProduct="true"></color-radio-button>
</template>

<script>
import store from '../store'
import ColorRadioButton from "./ColorRadioButton";

export default {
  name: "ProductColor",
  components: {
    ColorRadioButton,
  },
  props: {
    color: {
      type: Object,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    modelValue: {
      required: false,
    },
  },
  data() {
    return {
      store: store,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    isMobile: function () {
      return this.windowWidth < 920;
    },
    isCustomColorSelected() {
      return store.state.selectedOptions.hasOwnProperty('v_color') && store.state.selectedOptions.v_color.vSkuModificator === 'CC';
    }
  },
  mounted () {
    window.onresize = () => {
      setTimeout(() => {
        this.windowWidth = window.innerWidth;
      }, 300);
    }
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 60em) {
  .product__color {
    .color-radio__helper::before {
      display: none;
    }
    .color-radio__hint {
      display: none!important;
    }
  }
}
</style>
