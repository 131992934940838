var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overflow-hidden" },
    [
      _c(
        "transition",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        _vm._l(_vm.privateState.els, function (headline, i) {
          return i === _vm.privateState.activeEl
            ? _c(
                "h2",
                {
                  key: i,
                  staticClass:
                    "absolute top-0 left-0 serif f4 f3-ns f1-l lh-title w-100 white",
                },
                [_vm._v(_vm._s(headline))]
              )
            : _vm._e()
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }