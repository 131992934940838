var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product__expand" }, [
    _c(
      "div",
      {
        staticClass: "product__expand-title",
        class: { "product__expand-title_show": _vm.isExpanded },
        on: { click: _vm.toggle },
      },
      [
        _vm._v(_vm._s(_vm.title) + "\n    "),
        _vm.notification
          ? _c("div", { staticClass: "product__expand-notification" }, [
              _vm._v("\n      " + _vm._s(_vm.notification) + "\n    "),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded,
            expression: "isExpanded",
          },
        ],
        staticClass: "product__expand-content",
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.selected
      ? _c("div", { staticClass: "product__expand-selected" }, [
          _vm.showName
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.customText ? _vm.customText : _vm.selected.title)
                ),
              ])
            : _vm._e(),
          _vm._v(
            "\n    " +
              _vm._s(_vm._f("toEur")(_vm.selected.vPriceModificator)) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }