var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__v_corners" },
    _vm._l(_vm.items, function (corner) {
      return _c(
        "div",
        {
          key: corner.id,
          staticClass: "product__corner",
          class: { product__corner_active: _vm.isActive(corner.id) },
          on: {
            click: function ($event) {
              return _vm.changeValue(corner)
            },
          },
        },
        [
          _c("div", {
            staticClass: "product__corner-image expand__option",
            style: _vm.computedStyle(corner),
          }),
          _vm._v(" "),
          _c("div", { staticClass: "product__corner-title" }, [
            _vm._v(_vm._s(corner.title)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product__corner-cost" }, [
            _vm._v(_vm._s(_vm._f("toEur")(corner.vPriceModificator))),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }