var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-more__wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "product__show-more",
          class: {
            "product__show-more_offset":
              typeof _vm.$slots.default === "undefined",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.isPopupShown = true
            },
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm._translate("More information")))]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isPopupShown
        ? _c("popup-component", {
            attrs: { title: _vm.title, html: _vm.description },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }