var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb0 h3 relative z-1 cf" },
    [
      _c("ValidationProvider", {
        attrs: {
          mode: "lazy",
          vid: _vm.name,
          name: _vm.label,
          rules: _vm.validation,
          tag: "div",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentValue,
                        expression: "currentValue",
                      },
                    ],
                    staticClass:
                      "relative z-2 pt1 input-reset white bb bt-0 bl-0 br-0 b--white w-100 f5 outline-0 ba--transparent absolute top-0 br0 left-0 label-slide__input",
                    class: [
                      errors.length
                        ? "b--red"
                        : !_vm.currentValue
                        ? "b--white"
                        : "b--green",
                    ],
                    attrs: { name: _vm.name, id: _vm.name },
                    on: {
                      focus: _vm.handleFocus,
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currentValue = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.handleChange,
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "", selected: "", value: "" } },
                      [_vm._v(_vm._s(_vm.blank))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.choices, function (choice) {
                      return _c(
                        "option",
                        {
                          key: choice.id,
                          staticClass: "black",
                          domProps: { value: choice.id },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(choice.title) + "\n      "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "absolute top-0 left-0 z-0 o-50 transition dib",
                    attrs: { for: _vm.name },
                  },
                  [
                    _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                    _c("span", [_vm._v(" *")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: { css: false },
                    on: { enter: _vm.errorEnter, leave: _vm.errorLeave },
                  },
                  [
                    errors.length
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "bg-near-black z-2 red absolute top-4 right-0 pa1",
                          },
                          [_vm._v("\n      " + _vm._s(errors[0]) + "\n    ")]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }