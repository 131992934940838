var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__lightning" },
    [
      _vm._l(_vm.filtered_leds, function (led) {
        return _c("expand-horizontal-option", {
          key: led.id,
          class: {
            expand__option_active: _vm.isActive(led.id, "v_led"),
            expand__option_disabled:
              _vm.disabled && !_vm.isActive(led.id, "v_led"),
          },
          attrs: {
            title: led.title,
            image: _vm.image(led),
            cost: led.vPriceModificator,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.changeValue(led, true)
            },
          },
        })
      }),
      _vm._v(" "),
      _vm.selectedLed &&
      _vm.selectedLed.vAvailPosition &&
      Object.keys(_vm.selectedLed.vAvailPosition).length
        ? _c("div", { staticClass: "product__lightning-section" }, [
            _c("div", { staticClass: "product__lightning-section-title" }, [
              _vm._v(_vm._s(_vm._translate("Cable position"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "product__lightning-select-positions" },
              _vm._l(_vm.selectedLed.vAvailPosition, function (position, i) {
                return _c("expand-horizontal-option", {
                  key: i,
                  staticClass: "product__lightning-select-position",
                  class: {
                    "product__lightning-select-position_left":
                      position.value === "left",
                    "product__lightning-select-position_active":
                      position.value === _vm.additionInfo.position,
                  },
                  attrs: {
                    title: _vm._translate(position.label),
                    image: _vm.positionImage(position.label),
                    cutout: true,
                    short: true,
                    info: _vm.selectedLed.vDescription,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changePosition(position.value)
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedLed && _vm.selectedLed.id !== 58493
        ? _c("div", [
            _c("div", { staticClass: "product__lightning-section-title" }, [
              _vm._v(_vm._s(_vm._translate("Control system"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "product__lightning-select-positions" },
              _vm._l(_vm.filtered_control_system, function (control_system) {
                return _c("expand-horizontal-option", {
                  key: control_system.id,
                  staticClass: "expand__option_left",
                  class: {
                    expand__option_active: _vm.isActive(
                      control_system.id,
                      "v_control_system"
                    ),
                  },
                  attrs: {
                    title: control_system.title,
                    cost: control_system.vPriceModificator,
                    info: control_system.description,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changeCostPrice(
                        "v_control_system",
                        control_system
                      )
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedLed &&
      _vm.selectedLed.id !== 58493 &&
      _vm.selectedControlSystem !== 147802
        ? _c("div", { staticClass: "product__lightning-section" }, [
            _c("div", { staticClass: "product__lightning-section-title" }, [
              _vm._v(_vm._s(_vm._translate("Choose system"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "product__lightning-grid" },
              _vm._l(_vm.filtered_lighting_plan, function (lighting_plan) {
                return _c("radio-button-extended", {
                  key: lighting_plan.id,
                  attrs: {
                    title: lighting_plan.title,
                    "model-value": _vm.selectedLightingPlan,
                    info: lighting_plan.description,
                    "info-title": lighting_plan.title,
                    value: lighting_plan.id,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changeCostPrice(
                        "v_lighting_plan",
                        lighting_plan
                      )
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }