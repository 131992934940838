var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-component" }, [
    _c(
      "div",
      {
        staticClass: "select-component__label",
        class: { "select-component__label_shown": _vm.value },
      },
      [_vm._v(_vm._s(_vm.computedPlaceholder))]
    ),
    _vm._v(" "),
    _c(
      "select",
      _vm._b(
        {
          staticClass: "select-component__input",
          class: { invalid: _vm.invalid, success: _vm.success, init: _vm.init },
          attrs: { required: _vm.required },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        "select",
        {
          value: _vm.value,
          type: _vm.type,
          name: _vm.name,
          placeholder: _vm.computedPlaceholder,
        },
        false
      ),
      [
        _c("option", { attrs: { value: "", disabled: "", selected: "" } }, [
          _vm._v(_vm._s(_vm.computedPlaceholder)),
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function (option, id) {
          return _c("option", { domProps: { value: id } }, [
            _vm._v(_vm._s(option)),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.$slots
      ? _c(
          "div",
          { staticClass: "select-component__error-text" },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }