<template>
  <div>
    <div class="product__colors" v-if="!isMobile">
      <a v-for="color in colors"
         :key="color.id"
         :href="color.url"
         :title="color.title"
         @click="chooseColor(color)"
         class="product__color"
      >
        <product-color
            @click="chooseColor"
            :color="color"
            v-if="selectedColor"
            :info-text="computedInfo(color)"
            :model-value="selectedColor"
            :value="color.id"
        />
      </a>
<!--      <div v-if="isCustomColorSelected && !isMobile"-->
<!--           class="js-ral-colors-palette">-->
<!--        <ral-color-palette-selector />-->
<!--      </div>-->
    </div>
    <color-dropdown
        v-if="selectedColor && isMobile"
        :selected-color="selectedColor"
        :product="product"
        :note="_translate('Choose a custom color during the ordering process')"
    >
      <a v-for="(color, index) in colors"
         :key="color.id"
         :href="color.url"
         :title="color.title"
         @click="chooseColor(color)"
         class="product__color"
      >
        <product-color
            :color="color"
            v-if="selectedColor"
            :info-text="computedInfo(color)"
            :model-value="selectedColor"
            :value="color.id"
        />
        <sup class="product__asterisk" v-if="index === colors.length - 1">*</sup>
      </a>
    </color-dropdown>
<!--    <div v-if="isMobile && selectedColor.vSkuModificator ==='CC' && isCustomColorSelected"-->
<!--         class="hyphenate js-ral-colors-palette">-->
<!--      <ral-color-palette-selector />-->
<!--    </div>-->
  </div>
</template>

<script>
import ColorRadioButton from './ColorRadioButton.vue'
import ViempieField from '../components/ViempieField'
import RalColorPaletteSelector from '../components/RalColorPalette/RalColorPalette.vue'
import PopupComponent from "./PopupComponent.vue";

import store from '../store'
import ColorDropdown from "./ColorDropdown.vue";

export default {
  name: 'CategoryColorSelector',
  components: {
    PopupComponent,
    RalColorPaletteSelector,
    ColorDropdown,
    ColorRadioButton,
    ViempieField,
  },
  props: {
    initColor: {
      type: Object,
      required: true
    },
    colors: {
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    href: {
      type: String,
      required: true,
    }
  },
  mounted () {
    const customColorSKU = 'CC';
    if (this.initColor.vSkuModificator === customColorSKU) {
      this.changeCustom();
    }
  },
  data: () => ({
    // isPopupShown: this.isMobile && this.isCustomColorSelected,
    store: store,
    state: store.state,
    custom: !!store.state.additionInfo.v_color,
    customColor: store.state.additionInfo.v_color ? store.state.additionInfo.v_color : '',
    // popupClosed: false,
  }),
  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },
    selectedColor() {
      return store.getSelectedOption('v_color')
    },
    isCustomColorSelected() {
      return store.state.selectedOptions.hasOwnProperty('v_color') && store.state.selectedOptions.v_color.vSkuModificator === 'CC';
    },
  },
  methods: {
    _translate (key) { return store._translates(key)},
    chooseColor(color) {
      store.setSelectedOption('v_color', color)

      if (color.vSkuModificator !== 'CC') {
        // store.setSelectedOption('v_color')
      }

      if (color.vSkuModificator === 'CC') {
        // this.popupClos ed = false;
      }
    },
    // closePopup() {
    //   this.popupClosed = true;
    // },
    computedInfo(color) {
      if (color.vSkuModificator === 'CC') {
        return this._translate('Choose your own custom color in the next step');
      }

      return '';
    },
  },
  watch: {
    'state.selectedOptions.v_color': {
      handler: function(newValue) {
        if (newValue && this.selectedColor.vSkuModificator === 'CC') {
          window.location.href = this.href;
        }
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.product {
  &__meta {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 2rem;

    @media screen and (max-width: 60em) {
      align-items: stretch;
      margin-top: -2rem;
    }
  }

  &__image {
    margin-bottom: 1.5rem;
  }

  &__title {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.2;

    a {
      &:hover, &:focus {
        color: #ffd600;
      }
    }
  }

  &__promo {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffd600;
  }

  &__price {
    margin-top: 0.5rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;

    @media screen and (max-width: 60em) {
      margin-top: 1.5rem;
    }
  }

  &__modification {
    line-height: 1.5rem;
    color: #ffd600;
    margin-bottom: 0.5rem;
  }

  &__colors {
    display: flex;
    gap: 0.75rem;
    position: relative;

    @media screen and (max-width: 60em) {
      gap: 0.25rem;
    }
  }

  &__color {
    position: relative;
  }

  &__asterisk {
    position: absolute;
    top: 40%;
    left: 100%;
    font-size: inherit;
  }

  &__blocked {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, .9);
    z-index: 1;

    &-content {
      margin: auto;
    }
  }

  &__show {
    color: #ffd600;
    margin-left: 1rem;
    cursor: pointer;
  }

  &__info {
    position: relative;
    display: flex;

    @media screen and (max-width: 60em) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 1.5rem;

    &--columns {
      display: flex;
      align-items: flex-start;

      .w-50-l {
        width: 100%;
      }
      .product__info {
        flex-direction: column;
        order: -1;
      }
      .product__meta {
        margin-top: 1.5rem;
        margin-left: 0;
        padding-left: 0;

        @media screen and (max-width: 60em) {
          margin-top: 1rem;
        }
      }
      .product__price {
        @media screen and (max-width: 60em) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.js-ral-colors-palette {
  margin-top: 1rem;
}
</style>

