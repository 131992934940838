<template>
  <div class="login-form__wrapper">
    <form class="login-form" method="post">
      <input type="hidden" name="redirect" :value="checkoutUrl">
      <input type="hidden" name="action" value="users/login">
      <input type="hidden" :name="csrfParam" :value="csrfToken">
      <input-component v-model="$v.email.$model"
                       name="loginName"
                       class="login-form__input"
                       type="text"
                       :required="true"
                       :invalid="$v.email.$invalid && $v.email.$dirty"
                       :success="!$v.email.$invalid && $v.email.$dirty"
                       :placeholder="_translate('Email address')">
        <template v-if="!$v.email.required && $v.email.$dirty">
          {{ _translate('This field is required') }}
        </template>
        <template v-if="!$v.email.email && $v.email.$dirty">
          {{ _translate('Email is invalid') }}
        </template>
      </input-component>
      <input-component v-model="$v.password.$model"
                       name="password"
                       class="login-form__input"
                       type="password"
                       :required="true"
                       :invalid="$v.password.$invalid && $v.password.$dirty"
                       :success="!$v.password.$invalid && $v.password.$dirty"
                       :placeholder="_translate('Password')">
        <template v-if="!$v.password.required && $v.password.$dirty">
          {{ _translate('This field is required') }}
        </template>
        <template v-if="!$v.password.minLength && $v.password.$dirty">
          {{ _translate('Minimal field length:') }} {{ $v.password.$params.minLength.min }}
        </template>
      </input-component>
      <div class="login-form__actions">
        <button class="login-form__submit"
                type="submit"
                :disabled="$v.$invalid">
          {{ _translate('Log in') }}
        </button>
        <a :href="forgotUrl"
           class="login-form__forgot-link">
          {{ _translate('Forgot your password?') }}
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import InputComponent from "./InputComponent";
import store from '../store'

export default {
  name: "LoginForm",
  components: {
    InputComponent,
  },
  props: {
    forgotUrl: {
      type: String,
      required: true,
    },
    checkoutUrl: {
      type: String,
      required: true,
    },
    csrfParam: {
      type: String,
      required: true,
    },
    csrfToken: {
      type: String,
      required: true,
    },
  },
  methods:{
    _translate (key) { return store._translates(key)},
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
}
</script>

<style lang="scss">
  .login-form {
    &__input {
      margin-bottom: 2.5rem;

      @media screen and (max-width: 60em) {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 72rem) {
        .input-component__error-text {
          position: static;
        }
      }
    }

    &__actions {
      @media screen and (max-width: 60em) {
        margin-top: 3rem;
      }

      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__submit {
      height: 3rem;
      line-height: 3rem;
      padding: 0 2.5rem;
      background-color: #ffd700;
      border: none;
      outline: none;
      cursor: pointer;
      font-family: FuturaPT,serif;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__forgot-link {
      text-decoration: underline;
    }
  }
</style>
