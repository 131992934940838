var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkout__checkout-sum" },
    [
      _c("div", { staticClass: "checkout__checkout-row" }, [
        _c("div", { staticClass: "checkout__checkout-row-title" }, [
          _vm._v(_vm._s(_vm._translate("Subtotal"))),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "checkout__checkout-row-value",
          domProps: { innerHTML: _vm._s(_vm.selfSubtotal) },
        }),
      ]),
      _vm._v(" "),
      _vm._l(_vm.selfProducts, function (product, n) {
        return _c("div", { key: n, staticClass: "checkout__checkout-row" }, [
          _c("div", { staticClass: "checkout__checkout-row-title" }, [
            _vm._v(_vm._s(product.title)),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "checkout__checkout-row-value",
            domProps: { innerHTML: _vm._s(product.value) },
          }),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "checkout__checkout-row checkout__checkout-row_total" },
        [
          _c("div", { staticClass: "checkout__checkout-row-title" }, [
            _vm._v(_vm._s(_vm._translate("Total"))),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "checkout__checkout-row-value",
            domProps: { innerHTML: _vm._s(_vm.selfTotal) },
          }),
        ]
      ),
      _vm._v(" "),
      _c("voucher-component", {
        attrs: { coupon: _vm.coupon },
        on: { updateCart: _vm.updateCart },
      }),
      _vm._v(" "),
      _vm.$slots ? _c("div", [_vm._t("default")], 2) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }