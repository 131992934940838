var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart__accessories" }, [
    _c("div", { staticClass: "cart__accessories-title" }, [
      _vm._v("Must have accessories"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cart__accessories-list" },
      _vm._l(_vm.accessories, function (item) {
        return _c("accessory-component", {
          key: item.id,
          attrs: { item: item },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }