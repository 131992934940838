import { render, staticRenderFns } from "./ColorSamplesVariant.vue?vue&type=template&id=1bbd8bac&scoped=true"
import script from "./ColorSamplesVariant.vue?vue&type=script&lang=js"
export * from "./ColorSamplesVariant.vue?vue&type=script&lang=js"
import style0 from "./ColorSamplesVariant.vue?vue&type=style&index=0&id=1bbd8bac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbd8bac",
  null
  
)

export default component.exports