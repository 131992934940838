var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
    [
      this.selectedSamples.length > 0
        ? _c("div", { staticClass: "color-samples-selector" }, [
            this.isMobile
              ? _c(
                  "button",
                  {
                    staticClass: "expand-products",
                    class: { expanded: this.expandedProducts },
                    attrs: { type: "button" },
                    on: { click: _vm.toggleExpandedProducts },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24px",
                          height: "24px",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289L4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071C4.68342 13.0976 5.31658 13.0976 5.70711 12.7071L12 6.41421L18.2929 12.7071C18.6834 13.0976 19.3166 13.0976 19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L12.7071 4.29289ZM12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L12.7071 11.2929Z",
                            fill: "#ffd600",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              {
                ref: "form",
                attrs: { method: "POST" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.maybeUpdateCart.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "action",
                    value: "commerce/cart/update-cart",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "color-samples-selector-info" }, [
                  _c(
                    "p",
                    {
                      staticClass: "f5 f3-l serif",
                      class: {
                        yellow:
                          this.selectedSamples.length === this.sampleLimit,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(this.selectedSamples.length) +
                          " / " +
                          _vm._s(this.sampleLimit) +
                          " " +
                          _vm._s(_vm._translate("Samples")) +
                          " " +
                          _vm._s(_vm._translate("Selected").toLowerCase()) +
                          "\n          "
                      ),
                      _vm.isCartSamplesLimitReached
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "product__promo product__promo_warning",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.sprintf(
                                      _vm._translate(
                                        "Maximum %s samples per order"
                                      ),
                                      _vm.sampleLimit
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.canAddToCart && !_vm.otherProductTypesInCart
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column-reverse items-end flex-row-l items-center-l",
                        },
                        [
                          _c("span", { staticClass: "mt3 mt0-l dn di-l" }, [
                            _vm._v("€" + _vm._s(this.totalPrice) + ",-"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "color-samples-selector-add-to-cart black f6 f5-l dn db-l",
                              attrs: {
                                type: "submit",
                                disabled: _vm.state.cartUpdated,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._translate("Add to cart")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.otherProductTypesInCart
                    ? _c("p", { staticClass: "limit-warning" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._translate(
                                "Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order."
                              )
                            )
                          ),
                        ]),
                      ])
                    : _vm.canAddToCart && _vm.isMaximumSamplesSelected
                    ? _c("p", { staticClass: "limit-warning" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._translate("You're at the sample limit")
                            ) + "."
                          ),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._translate(
                                "Add your selection to the cart to proceed"
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "color-samples-selector-products",
                    class: { "expanded-products": this.expandedProducts },
                  },
                  [
                    _vm._l(_vm.selectedSamples, function (variant, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "color-samples-selector-product",
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: "purchasables[" + index + "][id]",
                              id: "inputPurchasableId",
                            },
                            domProps: { value: variant.id },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: "purchasables[" + index + "][qty]",
                              value: "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "color-samples-selector-product-color",
                            style: [
                              variant.finishType !== null &&
                              variant.finishType.id === 66314
                                ? {
                                    "background-image":
                                      "linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                    backgroundColor: variant.sampleColor.rgb,
                                  }
                                : { backgroundColor: variant.sampleColor.rgb },
                            ],
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "color-samples-selector-product-info",
                            },
                            [
                              _c("p", [_vm._v(_vm._s(variant.title))]),
                              _vm._v(" "),
                              _c("p", { staticClass: "grey" }, [
                                _vm._v(
                                  _vm._s(
                                    variant.finishType
                                      ? variant.finishType.title
                                      : variant.colorCode
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "color-samples-selector-remove-btn",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSelectedSample(
                                        variant.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._translate("Remove")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.samplesLeft, function (item) {
                      return _c(
                        "div",
                        { staticClass: "color-samples-selector-product" },
                        [
                          _c("div", {
                            staticClass:
                              "color-samples-selector-product-color empty-product",
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.canAddToCart
                  ? _c("span", { staticClass: "di dn-l" }, [
                      _vm._v("€" + _vm._s(this.totalPrice) + ",-"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddToCart
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "color-samples-selector-add-to-cart black f6 f5-l db dn-l",
                        attrs: {
                          type: "submit",
                          disabled: _vm.state.cartUpdated,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm._translate("Add to cart")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }