<template>
  <div class="product__cuts-out">
    <product-cut-out-option v-for="cutout in items"
                            :disabled="disabled"
                            :option="cutout"
                            :key="cutout.id"/>
  </div>
</template>

<script>
import ProductCutOutOption from "./ProductCutOutOption";
import store from '../store'

export default {
  name: "ProductCutOuts",
  components: {
    ProductCutOutOption,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clickHandler() {
      return !this.disabled
    },
  },
  data () {
    return {
      state: store.state,
    }
  },
  computed: {
  },
  beforeMount () {
    // this.state.selectedOptions.v_depth
  }
}
</script>

<style scoped lang="scss">
.product__cuts-out {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
