var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "color-radio-button",
    _vm._b(
      { attrs: { isMobile: _vm.isMobile, isProduct: true } },
      "color-radio-button",
      {
        color: _vm.color,
        value: _vm.value,
        modelValue: _vm.modelValue,
        infoText: _vm.infoText,
      },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }