var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter" },
    [
      _vm.isMobile
        ? _c(
            "div",
            { staticClass: "filter__buttons" },
            [
              _vm.model.slug !== "accessories"
                ? [
                    _vm.isSectionClear.length
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "filter__button filter__button--default",
                            class: {
                              "filter__button--active":
                                _vm.isSectionShow.length,
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.openFilterSection("length")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm._translate("Length")))]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "filter__button",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.clearFilterSection("length")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.store.getSelectedOption("v_len").vMinLen
                                ) +
                                " cm\n          "
                            ),
                            _c(
                              "span",
                              { staticClass: "filter__button-clear" },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "10",
                                      height: "10",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5",
                                        fill: "#B2B2B2",
                                        "fill-rule": "evenodd",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isSectionClear.depth
                ? _c(
                    "button",
                    {
                      staticClass: "filter__button filter__button--default",
                      class: {
                        "filter__button--active": _vm.isSectionShow.depth,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openFilterSection("depth")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm._translate("Depth")))]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "filter__button",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clearFilterSection("depth")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.store.getSelectedOption("v_depth").vDepth
                          ) +
                          " cm\n        "
                      ),
                      _c("span", { staticClass: "filter__button-clear" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "10",
                              height: "10",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5",
                                fill: "#B2B2B2",
                                "fill-rule": "evenodd",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
              _vm._v(" "),
              _vm.model.slug !== "accessories"
                ? [
                    _vm.isSectionClear.color
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "filter__button filter__button--default",
                            class: {
                              "filter__button--active": _vm.isSectionShow.color,
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.openFilterSection("color")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm._translate("Color")))]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "filter__button",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.clearFilterSection("color")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.store.getSelectedOption("v_color").title
                                ) +
                                "\n          "
                            ),
                            _c(
                              "span",
                              { staticClass: "filter__button-clear" },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "10",
                                      height: "10",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5",
                                        fill: "#B2B2B2",
                                        "fill-rule": "evenodd",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    !_vm.store.isMobile()
                      ? [
                          typeof _vm.store.getSelectedOption("v_cutouts")
                            .slug === "undefined"
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "filter__button filter__button--default",
                                  class: {
                                    "filter__button--active":
                                      _vm.isSectionShow.cutouts,
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openFilterSection("cutouts")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm._translate("Add-ons")) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "filter__button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearFilterSection("cutouts")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.store.getSelectedOption("v_cutouts")
                                          .title
                                      ) +
                                      "\n            "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "filter__button-clear" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "10",
                                            height: "10",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5",
                                              fill: "#B2B2B2",
                                              "fill-rule": "evenodd",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("FilterSection", {
        ref: "models",
        class: { "filter__wrapper--show": _vm.isSectionShow.models },
        attrs: {
          title: _vm._translate("Models"),
          property: "v_model",
          options: _vm.availableModels,
          label: "models",
        },
        model: {
          value: _vm.model.id,
          callback: function ($$v) {
            _vm.$set(_vm.model, "id", $$v)
          },
          expression: "model.id",
        },
      }),
      _vm._v(" "),
      _vm.lengths && _vm.model.slug !== "accessories"
        ? _c("FilterSection", {
            ref: "length",
            class: { "filter__wrapper--show": _vm.isSectionShow.length },
            attrs: {
              title: _vm._translate("Popular shelf lengths"),
              "class-name": "mw300",
              "is-clearable": true,
              property: "v_len",
              "custom-length-product": _vm.customLengthProduct,
              filters: _vm.filters,
              options: _vm.lengths,
              short: true,
              "shown-items-count": 6,
              label: "length",
            },
            on: {
              clear: function ($event) {
                return _vm.clearFilterSection("length")
              },
            },
            model: {
              value: _vm.store.getSelectedOption("v_len").id,
              callback: function ($$v) {
                _vm.$set(_vm.store.getSelectedOption("v_len"), "id", $$v)
              },
              expression: "store.getSelectedOption('v_len').id",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.depths
        ? _c("FilterSection", {
            ref: "depth",
            class: { "filter__wrapper--show": _vm.isSectionShow.depth },
            attrs: {
              title: _vm._translate("Shelf depth"),
              "is-clearable": true,
              property: "v_depth",
              options: _vm.depths,
              label: "depth",
            },
            on: {
              clear: function ($event) {
                return _vm.clearFilterSection("depth")
              },
            },
            model: {
              value: _vm.store.getSelectedOption("v_depth").id,
              callback: function ($$v) {
                _vm.$set(_vm.store.getSelectedOption("v_depth"), "id", $$v)
              },
              expression: "store.getSelectedOption('v_depth').id",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filters.v_color && _vm.model.slug !== "accessories"
        ? _c("FilterSection", {
            ref: "color",
            class: { "filter__wrapper--show": _vm.isSectionShow.color },
            attrs: {
              title: _vm._translate("Color"),
              type: "color",
              property: "v_color",
              options: _vm.store.state.filters.v_color,
              "reset-button-text": _vm._translate("Reset filter"),
              "show-button-text": _vm._translate("All colors"),
              "shown-items-count": 12,
              label: "color",
            },
            model: {
              value: _vm.store.getSelectedOption("v_color"),
              callback: function ($$v) {
                _vm.$set(_vm.store, "getSelectedOption('v_color')", $$v)
              },
              expression: "store.getSelectedOption('v_color')",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }