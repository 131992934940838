import { render, staticRenderFns } from "./RalColorPalette.vue?vue&type=template&id=7dae0666&scoped=true"
import script from "./RalColorPalette.vue?vue&type=script&lang=js"
export * from "./RalColorPalette.vue?vue&type=script&lang=js"
import style0 from "./RalColorPalette.vue?vue&type=style&index=0&id=7dae0666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dae0666",
  null
  
)

export default component.exports