var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.includes(
        ["text", "email", "tel", "password", "search", "number"],
        _vm.type
      )
        ? _c("ValidationProvider", {
            attrs: {
              mode: "lazy",
              vid: _vm.name,
              name: _vm.label,
              rules: _vm.validation,
              tag: "div",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _vm.type === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentValue,
                                expression: "currentValue",
                              },
                            ],
                            staticClass:
                              "relative bg-transparent z-1 input-reset w-100 f5 outline-0 white bb bt-0 bl-0 br-0 br0",
                            class: [
                              errors.length
                                ? "b--red"
                                : !_vm.currentValue
                                ? "b--white"
                                : "b--green",
                              _vm.disabled ? "o-30" : null,
                            ],
                            style: _vm.styles,
                            attrs: {
                              placeholder: [
                                _vm.validation &&
                                _vm.validation.includes("required")
                                  ? `${_vm.placeholder} *`
                                  : _vm.placeholder,
                              ],
                              id: _vm.name,
                              autocomplete: _vm.autocomplete,
                              name: _vm.name,
                              readonly: _vm.readonly === "true",
                              disabled: _vm.disabled,
                              type: "checkbox",
                            },
                            domProps: {
                              value: _vm.currentValue,
                              checked: Array.isArray(_vm.currentValue)
                                ? _vm._i(_vm.currentValue, _vm.currentValue) >
                                  -1
                                : _vm.currentValue,
                            },
                            on: {
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                              input: _vm.handleInput,
                              change: function ($event) {
                                var $$a = _vm.currentValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = _vm.currentValue,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.currentValue = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.currentValue = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.currentValue = $$c
                                }
                              },
                            },
                          })
                        : _vm.type === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentValue,
                                expression: "currentValue",
                              },
                            ],
                            staticClass:
                              "relative bg-transparent z-1 input-reset w-100 f5 outline-0 white bb bt-0 bl-0 br-0 br0",
                            class: [
                              errors.length
                                ? "b--red"
                                : !_vm.currentValue
                                ? "b--white"
                                : "b--green",
                              _vm.disabled ? "o-30" : null,
                            ],
                            style: _vm.styles,
                            attrs: {
                              placeholder: [
                                _vm.validation &&
                                _vm.validation.includes("required")
                                  ? `${_vm.placeholder} *`
                                  : _vm.placeholder,
                              ],
                              id: _vm.name,
                              autocomplete: _vm.autocomplete,
                              name: _vm.name,
                              readonly: _vm.readonly === "true",
                              disabled: _vm.disabled,
                              type: "radio",
                            },
                            domProps: {
                              value: _vm.currentValue,
                              checked: _vm._q(
                                _vm.currentValue,
                                _vm.currentValue
                              ),
                            },
                            on: {
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                              input: _vm.handleInput,
                              change: function ($event) {
                                _vm.currentValue = _vm.currentValue
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentValue,
                                expression: "currentValue",
                              },
                            ],
                            staticClass:
                              "relative bg-transparent z-1 input-reset w-100 f5 outline-0 white bb bt-0 bl-0 br-0 br0",
                            class: [
                              errors.length
                                ? "b--red"
                                : !_vm.currentValue
                                ? "b--white"
                                : "b--green",
                              _vm.disabled ? "o-30" : null,
                            ],
                            style: _vm.styles,
                            attrs: {
                              placeholder: [
                                _vm.validation &&
                                _vm.validation.includes("required")
                                  ? `${_vm.placeholder} *`
                                  : _vm.placeholder,
                              ],
                              id: _vm.name,
                              autocomplete: _vm.autocomplete,
                              name: _vm.name,
                              readonly: _vm.readonly === "true",
                              disabled: _vm.disabled,
                              type: _vm.type,
                            },
                            domProps: {
                              value: _vm.currentValue,
                              value: _vm.currentValue,
                            },
                            on: {
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.currentValue = $event.target.value
                                },
                                _vm.handleInput,
                              ],
                            },
                          }),
                      _vm._v(" "),
                      _c(
                        "transition",
                        {
                          attrs: { css: false },
                          on: { enter: _vm.errorEnter, leave: _vm.errorLeave },
                        },
                        [
                          errors.length
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "bg-near-black z-2 red absolute top-4 right-0 pa1 form-error",
                                },
                                [
                                  _vm._v(
                                    "\n      " + _vm._s(errors[0]) + "\n    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              767704387
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "textarea"
        ? _c("ValidationProvider", {
            attrs: {
              mode: "lazy",
              vid: _vm.name,
              name: _vm.label,
              rules: _vm.validation,
              tag: "div",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentValue,
                            expression: "currentValue",
                          },
                        ],
                        staticClass:
                          "input-reset br0 relative bg-transparent z-1 input-reset w-100 f5 outline-0 white b--white bb bt-0 bl-0 br-0",
                        class: [
                          errors.length
                            ? "b--red"
                            : !_vm.currentValue
                            ? "b--white"
                            : "b--green",
                          _vm.disabled ? "o-30" : null,
                        ],
                        style: _vm.styles,
                        attrs: {
                          rows: "8",
                          placeholder: _vm.placeholder,
                          name: _vm.name,
                          id: _vm.name,
                          disabled: _vm.disabled,
                        },
                        domProps: { value: _vm.currentValue },
                        on: {
                          focus: _vm.handleFocus,
                          blur: _vm.handleBlur,
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.currentValue = $event.target.value
                            },
                            _vm.handleInput,
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "transition",
                        {
                          attrs: { css: false },
                          on: { enter: _vm.errorEnter, leave: _vm.errorLeave },
                        },
                        [
                          errors.length
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "bg-near-black z-2 red absolute top-4 right-0 pa1 form-error",
                                },
                                [
                                  _vm._v(
                                    "\n     " + _vm._s(errors[0]) + "\n    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              670739479
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("label", { attrs: { for: _vm.name } }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
            _vm.validation && _vm.validation.includes("required")
              ? _c("span", [_vm._v(" *")])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }