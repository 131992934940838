var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overflow-hidden" },
    [
      _vm._l(_vm.privateState.els, function (image, i) {
        return _c("div", {
          key: i,
          staticClass: "absolute z-0 absolute--fill cover bg-center",
          style: { backgroundImage: `url(${image})`, opacity: 0.1 },
        })
      }),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
        _vm._l(_vm.privateState.els, function (image, i) {
          return _c(
            "picture",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: i === _vm.privateState.activeEl,
                  expression: "i === privateState.activeEl",
                },
              ],
              key: `key-${i}`,
            },
            [
              _c("source", {
                attrs: { media: "(max-width: 560px)", srcset: image.mobile },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: { media: "(max-width: 1024px)", srcset: image.tablet },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass:
                  "absolute z-0 absolute--fill fit-cover h-100 w-100",
                attrs: { src: image.desktop, alt: image.alt },
              }),
            ]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }