<template>
  <div class="product__list_shown">
    <template v-if="configurations">
      <template v-if="filteredProducts.length > 0">
        <main-product-component
            v-for="(product, index) in filteredProducts"
            :configuration="getConfiguration(product)"
            :images="getImages(product)"
            :colors="getColors(product)"
            :product="product"
            :key="index">
          <div class="dark-gray"></div>
          <div class="d-flex align-items-center">
            <div class="w-50-l">
              <h3 class="product__title serif">{{ product.title }}</h3>
              <div class="product__modification">
                {{_translate('Popular')}}
              </div>
            </div>
          </div>
        </main-product-component>
      </template>
      <div class="product__info_warning" v-else>{{ _translate('Please adjust depth, length or add-ons to see available models' )}}</div>
    </template>
    <div v-else class="product__loader"></div>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store'

export default {
  name: 'ProductList',
  data () {
    return {
      configurations: null,
    };
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    axios.get('/products/configurations').then(({ data }) => {
      this.configurations = data;
      store.state.configurations = data
      const tempColors = []
      const colors = []
      for (let productKey in this.colors) {
        if (this.filteredProducts.find((product) => product.id === +productKey))
          for (let colorKey in this.colors[productKey]) {
            tempColors[`color${this.colors[productKey][colorKey].id}`] = this.colors[productKey][colorKey]
          }
      }

      for (let colorKey in tempColors) {
        colors.push(tempColors[colorKey])
      }

      this.$set(store.state.filters, 'v_color', colors)
    });
  },
  mounted() {
  },
  methods: {
    _translate (key) { return store._translates(key)},
    getImages (product) {
      return this.images[product.id]
    },
    getColors (product) {
      return this.colors[product.id]
    },
    getConfiguration (product) {
      let configuration = {}
      if (typeof this.configurations[product.id] !== 'undefined') {
        for (const key in this.configurations[product.id]) {
          try {
            if (this.configurations[product.id].hasOwnProperty(key) && this.filters.hasOwnProperty(key)) {
              configuration[key] = this.filters[key].find(item => +item.id === this.configurations[product.id][key])
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      return configuration
    }
  },
  computed: {
    filteredProducts () {
      let that = this;
      const allVmodelSlugs = this.filters.v_model.map(filter => filter.slug)
      const vmodel = store.getSelectedOption('v_model')
      const _getConfiguration = this.getConfiguration
      let products = this.products.filter(function(product) {
        const available = !(_getConfiguration(product).hasOwnProperty('v_cutouts')
            && store.getSelectedOption('v_cutouts').slug
            && _getConfiguration(product).v_cutouts.slug !== store.getSelectedOption('v_cutouts').slug)
        const vmodeSlugs = vmodel.id === -1 ? allVmodelSlugs : [vmodel.slug];
        if (!product.vmodelCategory.find(path => vmodeSlugs.includes(path))) {
          return false;
        }
        if (!available) {
          return false;
        }
        product.vModelSlug = product.vmodelCategory.reverse()[0];

        if (product.defaultSku === '108.9016' && store.getSelectedOption('v_model').slug !== 'room-divider') {
          return false;
        }

        let config = that.getConfiguration(product);
        if (config.hasOwnProperty('v_len') && config.v_len.vMinLen > store.getSelectedLengthVal()) {
          return false;
        }
        return !(config.hasOwnProperty('v_depth') && config.v_depth.vDepth > store.getSelectedOption('v_depth').vDepth);
      });

      if (vmodel.id !== -1) {
        products.sort((a, b) => a.vSort > b.vSort && 1 || -1);
      } else {
        const shelves = [];
        const accessories = [];

        for (const product of products) {
          if (['accessories', 'zubehoere'].indexOf(product.vModelSlug) !== -1) {
            accessories.push(product);
          } else {
            shelves.push(product);
          }
        }

        products = [
          ...shelves.sort((a, b) => a.vSort > b.vSort && 1 || -1),
          ...accessories.sort((a, b) => a.vSort > b.vSort && 1 || -1)
        ];
      }

      store.setProductsCount(products.length)
      return products
    },
  },
}
</script>

<style scoped lang="scss">
.product {
  &__info {
    &_warning {
      color: #ffd700;
    }
  }

  &__loader {
    max-width: 550px;
    height: 44px;
    background-image: url("/build/static/loader.gif");
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
