<template>
  <div class="length__wrapper">
    <div class="length__inputs">
      <div class="length__block">
        <div class="length__block-title">{{ _translate('Length') }}</div>
        <template v-if="custom">
          <div class="length__custom-input-wrapper">
            <input v-model="customLength" :min="customMin" :max="customMax"
                   @keypress="onlyNumbers"
                   :placeholder="_translate('Desired length')"
                   class="length__block-select product__select length__custom-input"/>
          </div>
        </template>
        <template v-else>
          <div class="length__block-select product__select"
               tabindex="1" v-if="state.selectedOptions.v_len">{{ state.selectedOptions.v_len.vMinLen + 'cm' }}
            <div class="length__block-variants product__variants length">
              <div v-for="length_variant in const_v_len"
                   :class="{'length__block-variant_active': length_variant.id === state.selectedOptions.v_len.id}"
                   @click="changeVariant(length_variant, 'v_len')"
                   class="length__block-variant">{{ length_variant.vMinLen + 'cm' }}
              </div>
              <div v-if="!custom"
                   class="length__block-custom-length nowrap"
                   @click="changeCustom">{{ _translate('Custom length') }}?
              </div>
            </div>
          </div>
        </template>
        <template v-if="custom && _store.isMobile()">
          <div v-if="this.customMax < customLength"
               class="length__error">Max {{ this.customMax }} cm
          </div>
          <div v-if="this.customMin > customLength"
               class="length__error">Min {{ this.customMin }} cm
          </div>
        </template>
        <div class="length__block-custom-block length__block-custom-block_mobile" v-if="_store.isMobile()">
          <div v-if="!custom"
               class="length__block-custom-length"
               @click="changeCustom">{{ _translate('Custom length?') }}
          </div>
          <template v-else>
            <div class="length__block-custom-length sans-serif"
                 @click="changeCustom">
              {{ _translate('Remove custom length') }}
            </div>
            <span>{{ price|toEur }}</span>
          </template>
        </div>
      </div>
      <div class="length__block">
        <div class="length__block-title">{{ _translate('Depth') }}</div>
        <div class="length__block-select product__select"
             tabindex="1" v-if="state.selectedOptions.v_depth">{{ state.selectedOptions.v_depth.vDepth + 'cm' }}
          <div class="length__block-variants product__variants">
            <div v-for="depth_variant in const_v_depth"
                 :class="{'length__block-variant_active': depth_variant.id === state.selectedOptions.v_depth.id}"
                 @click="changeVariant(depth_variant, 'v_depth')"
                 class="length__block-variant">{{ depth_variant.vDepth + 'cm' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="custom && !_store.isMobile()">
      <div v-if="this.customMax < customLength"
           class="length__error">Max {{ this.customMax }} cm
      </div>
      <div v-if="this.customMin > customLength"
           class="length__error">Min {{ this.customMin }} cm
      </div>
    </template>
    <div class="length__block-custom-block" v-if="!_store.isMobile()">
      <div v-if="!custom"
           class="length__block-custom-length"
           @click="changeCustom">{{ _translate('Custom length?') }}
      </div>
      <template v-else>
        <div class="length__block-custom-length sans-serif"
             @click="changeCustom">
          {{ _translate('Remove custom length') }}
        </div>
        <span>{{ price|toEur }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import {numericMixin} from '../../mixins/numericMixin';

export default {
  name: 'ProductLength',
  mixins: [numericMixin],
  props: {
    isCustomize: {
      type: Boolean,
      default: true,
    },
    configurations: {
      type: Object,
      required: true
    }
  },
  beforeMount () {
    const lengthCategory = this.state.filters.v_len.filter(len => len.id === this.configurations.v_len)[0]
    this.custom_v_len = this.state.filters.v_len.filter(len => len.vMaxLen)
    this.const_v_len = this.state.filters.v_len.filter(len => len.vMinLen >= lengthCategory.vMinLen && len.vMaxLen <= lengthCategory.vMaxLen)
    this.const_v_depth = this.state.filters.v_depth.filter(depth => depth.id >= this.configurations.v_depth)
    this.customMin = Math.min(...this.const_v_len.map(l => l.vMinLen))
    this.customMax = Math.max(...this.const_v_len.map(l => l.vMaxLen), ...this.const_v_len.map(l => l.vMinLen))
    if (this.state.selectedOptions.v_len){
      if (this.state.additionInfo.v_len === undefined){
        this.$emit('changeAdditionInfo', 'v_len', null);
      }
    } else {
      this.customLength = this.customMin
    }
  },
  mounted() {
    const lengthCategory = this.state.filters.v_len.filter(len => len.id === this.configurations.v_len)[0]
    this.custom = this.isCustomize
    this.const_v_len = this.state.filters.v_len.filter(len => len.vMinLen >= lengthCategory.vMinLen && len.vMaxLen <= lengthCategory.vMaxLen)
    this.const_v_depth = this.state.filters.v_depth.filter(depth => depth.id >= this.configurations.v_depth)
  },
  computed:{
    _store() {
      return store
    },
    price() {
      let p = 0;
      if (store.getSelectedOption('v_len')) p += store.getSelectedOption('v_len').vPriceModificator;
      if (store.getSelectedOption('v_depth')) p += store.getSelectedOption('v_depth').vPriceModificator;
      return p;
    }
  },
  watch:{
    custom(val) {
      if (!val) {
        this.$emit('customLengthCorrect', true)
      } else {
        this.$emit('customLengthCorrect', this.customMin <= this.customLength)
      }
    },
    customLength (val) {
      const parsedVal = this.parseNumber(val);
      const isCustomLimitsCorrect = parsedVal <= this.customMax && parsedVal >= this.customMin;
      if (!isCustomLimitsCorrect) {
        this.$emit('customLengthCorrect', false)
        return;
      }

      let len = this.const_v_len.find(l => l.vMinLen === +parsedVal)
      if (!len) {
        len = this.custom_v_len.find(l => l.vMinLen <= parsedVal && l.vMaxLen >= parsedVal)
        this.$emit('changeAdditionInfo', 'v_len', parsedVal)
      } else {
        this.$emit('changeAdditionInfo', 'v_len', null)
      }


      this.$emit('costChange', 'v_len', len)
      this.$emit('customLengthCorrect', true)
    }
  },
  data () {
    return {
      state: store.state,
      const_v_len: [],
      const_v_depth: [],
      custom_v_len: [],
      custom: !!store.state.additionInfo.v_len,
      customMin: 0,
      customMax: 0,
      customLength: null
    }
  },
  methods: {
    _translate (key) { return store._translates(key)},
    changeCustom () {
      if (this.custom <= this.customMax && this.custom >= this.customMin) {
        const len = [...this.const_v_len].reverse().find(l => l.vMinLen <= +this.customLength);
        this.$emit('costChange', 'v_len', len)
        this.$emit('changeAdditionInfo', 'v_len', null)
      }
      this.custom = !this.custom
    },
    changeVariant (value, key) {
      this[key] = value
      this.$emit('costChange', key, value)
      if (key === 'v_len') {
        this.$emit('changeAdditionInfo', 'v_len', null)
      }
      document.activeElement.blur()
    },
    onlyNumbers (e) {
      console.log(e)
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const num = parseInt(e.key);
      if (!numbers.includes(num)) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.length {
  &__error {
    color: #ff5858;
    margin-top: 8px;
  }

  &__wrapper {
    border-bottom: 1px solid #333333;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  &__custom-input {
    padding-right: 4rem;
    width: 100%;

    &-wrapper {
      position: relative;

      &::after {
        content: "cm";
        position: absolute;
        line-height: 24px;
        right: 16px;
        top: 12px;
      }
    }
  }

  &__inputs {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 60em) {
      flex-direction: column;
    }
  }

  &__block {
    flex-grow: 1;
    flex-basis: 50%;

    &-custom-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_mobile {
        margin-top: 1rem;
      }
    }

    &-custom-length {
      font-size: 1rem;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffd600;
      text-decoration: underline;
      cursor: pointer;
      background: transparent;
      padding: 0;
      border: 0;

      &:hover,
      &:focus {
        color: rgba(255, 214, 0, 0.8);
        text-decoration: underline;
      }

      &_hide {
        text-decoration: underline;
        &:hover,
        &:focus {
          color: #fff !important;
          text-decoration: none;
        }
      }
    }

    &-variants {
      padding: 1rem 1rem 0.5rem;
      border-radius: 2px;
      box-shadow: 0 0 2rem 0 #000;
      background-color: #333;
      position: absolute;
      margin-top: 2rem;
      left: 0;
      z-index: 2;
      min-width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(7, auto);
    }

    &-variant {
      min-width: 4rem;
      width: 30%;
      color: #fff;
      margin: 0 0 0.5rem;

      &_active,
      &:hover {
        color: #ffffff;
      }
    }

    &-title {
      font-family: Ladislav, serif;
      font-size: 1.5rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 1.5rem;
    }

    &-select {
      padding: 12px 16px;
      border-radius: 2px;
      border: solid 2px #333;
      cursor: pointer;
      position: relative;
      display: block;
      color: #fff;
      outline: none;
      font-family: "FuturaPT", sans-serif;
      line-height: 1.5;

      &:hover, &:focus {
        border-color: #616161;
      }
    }
  }
}
</style>
