var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _c("span", {
        domProps: {
          innerHTML: _vm._s(_vm.expanded ? _vm.fullText : _vm.shortText),
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "sans-serif f5 lh-copy bg-transparent bn outline-0 pointer product__show-more",
          attrs: {
            title: _vm.expanded ? _vm.btnLabelExpanded : _vm.btnLabelCollapsed,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            _vm._s(_vm.expanded ? _vm.btnLabelExpanded : _vm.btnLabelCollapsed)
          ),
        ]
      ),
      _vm._v(" "),
      _vm.writeReviewText && _vm.expanded
        ? _c(
            "a",
            { staticClass: "write-more dim", attrs: { href: _vm.contactUrl } },
            [_vm._v(_vm._s(_vm.writeReviewText))]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }