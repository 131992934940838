var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__cuts-out" },
    _vm._l(_vm.items, function (cutout) {
      return _c("product-cut-out-option", {
        key: cutout.id,
        attrs: { disabled: _vm.disabled, option: cutout },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }