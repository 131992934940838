var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "expand__option",
      class: { expand__option_left: _vm.left },
      style: _vm.computedStyle,
    },
    [
      _c("div", { staticClass: "expand__option-title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      !_vm.short
        ? _c("div", { staticClass: "expand__option-cost" }, [
            _vm._v(_vm._s(_vm._f("toEur")(_vm.cost))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.info
        ? _c(
            "MoreInformation",
            { attrs: { title: _vm.title, description: _vm.info } },
            [_c("div", { staticClass: "expand__option-info" })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }