var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__v_room_divider" },
    _vm._l(_vm.items, function (roomDivider) {
      return _c(
        "div",
        {
          key: roomDivider.id,
          staticClass: "product__corner",
          class: {
            product__corner_active:
              _vm.store.getSelectedOption("v_room_divider").id ===
              roomDivider.id,
          },
          on: {
            click: function ($event) {
              return _vm.changeValue(roomDivider)
            },
          },
        },
        [
          _c("div", {
            staticClass: "product__corner-image expand__option",
            style: _vm.computedStyle(roomDivider),
          }),
          _vm._v(" "),
          _c("div", { staticClass: "product__corner-title" }, [
            _vm._v(_vm._s(roomDivider.title)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product__corner-cost" }, [
            _vm._v(_vm._s(_vm._f("toEur")(roomDivider.vPriceModificator))),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }