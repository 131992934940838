var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list pa0 ma0 mb3" },
    _vm._l(_vm.gatewaysList, function (gateway) {
      return _c(
        "label",
        { key: gateway.id, attrs: { for: _vm.gatewayFor(gateway.id) } },
        [
          _c("li", { staticClass: "mb2 list-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.gatewayId,
                  expression: "gatewayId",
                },
              ],
              staticClass: "mr3",
              attrs: {
                type: "radio",
                id: _vm.gatewayFor(gateway.id),
                name: "gatewayId",
              },
              domProps: {
                value: gateway.id,
                checked: _vm._q(_vm.gatewayId, gateway.id),
              },
              on: {
                change: function ($event) {
                  _vm.gatewayId = gateway.id
                },
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(gateway.label))]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }