var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-dropdown" }, [
    _c(
      "div",
      { staticClass: "color-dropdown__value", attrs: { tabindex: "0" } },
      [
        _c("div", {
          staticClass: "color-dropdown__selected-color",
          class: {
            "custom-color-selected":
              _vm.selectedColor.hasOwnProperty("vSkuModificator") &&
              _vm.selectedColor.vSkuModificator === "CC",
          },
          style: { backgroundColor: _vm.selectedColor.color },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-dropdown__list mt3 pa3" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm.note
          ? _c("p", { staticClass: "color-dropdown__note" }, [
              _vm._v("* " + _vm._s(_vm.note)),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }