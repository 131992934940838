var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isMobile &&
    (_vm.catalogButton !== undefined || _vm.samplesButton !== undefined)
      ? _c("section", { staticClass: "color-samples-navigation" }, [
          _vm.catalogButton !== undefined
            ? _c(
                "a",
                {
                  staticClass: "color-samples-navigation-btn",
                  attrs: { href: "#samples-catalog" },
                },
                [
                  _c("div", [
                    _vm.catalogButton.title
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "serif color-samples-navigation-btn-title",
                          },
                          [_vm._v(_vm._s(_vm.catalogButton.title))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catalogButton.description
                      ? _c("p", [_vm._v(_vm._s(_vm.catalogButton.description))])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.catalogButton.price
                    ? _c("p", { staticClass: "price yellow" }, [
                        _vm._v("€" + _vm._s(_vm.catalogButton.price) + ",-"),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.samplesButton !== undefined
            ? _c(
                "a",
                {
                  staticClass: "color-samples-navigation-btn",
                  attrs: { href: "#samples-variants" },
                },
                [
                  _c("div", [
                    _vm.samplesButton.title
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "serif color-samples-navigation-btn-title",
                          },
                          [_vm._v(_vm._s(_vm.samplesButton.title))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.samplesButton.description
                      ? _c("p", [_vm._v(_vm._s(_vm.samplesButton.description))])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.samplesButton.price
                    ? _c("p", { staticClass: "price yellow" }, [
                        _vm._v("€" + _vm._s(_vm.samplesButton.price) + ",-"),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "color-samples-description",
        attrs: { id: "samples-catalog" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex justify-center items-center items-start-l flex-column flex-row-l",
          },
          [
            _vm.isMobile
              ? _c(
                  "h2",
                  { staticClass: "serif color-samples__description-title mb3" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.packProduct.title) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mediaPreview !== undefined
              ? [
                  _vm.mediaPreview.fileKind === "image"
                    ? _c("img", {
                        staticClass: "color-samples-description-media",
                        attrs: { src: _vm.mediaPreview.url },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaPreview.fileKind === "video"
                    ? _c(
                        "video",
                        {
                          staticClass: "color-samples-description-media",
                          attrs: {
                            muted: "",
                            autoplay: "",
                            loop: "",
                            playsinline: "",
                          },
                          domProps: { muted: true },
                        },
                        [
                          _c("source", {
                            attrs: {
                              type: _vm.mediaPreview.mimeType,
                              src: _vm.mediaPreview.url,
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "color-samples-description-block flex flex-column",
              },
              [
                !_vm.isMobile
                  ? _c(
                      "h2",
                      { staticClass: "serif color-samples__description-title" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.packProduct.title) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c("p", { staticClass: "price yellow mt2" }, [
                      _vm._v("€" + _vm._s(_vm.packProduct.defaultPrice) + ",-"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("expandable-description", {
                  attrs: {
                    "is-description": true,
                    "description-text": _vm.description,
                    "btn-label-collapsed": "Read more",
                    "btn-label-expanded": "Read less",
                  },
                }),
                _vm._v(" "),
                _vm.otherProductTypesInCart
                  ? _c("p", { staticClass: "yellow mb3" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._translate(
                              "Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order."
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between",
                    class: { "order-first": _vm.isMobile },
                  },
                  [
                    _c(
                      "form",
                      {
                        ref: "form",
                        attrs: { method: "POST" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updateCart.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "action",
                            value: "commerce/cart/update-cart",
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name:
                              "purchasables[" + _vm.packProduct.id + "][id]",
                            id: "inputPurchasableId",
                          },
                          domProps: { value: _vm.packProduct.defaultVariantId },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name:
                              "purchasables[" + _vm.packProduct.id + "][qty]",
                            value: "1",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "color-samples-pack__buy-btn pointer dim",
                            class: { disabled: _vm.otherProductTypesInCart },
                            attrs: { disabled: _vm.otherProductTypesInCart },
                          },
                          [_vm._v(_vm._s(_vm._translate("Buy")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isMobile
                      ? _c("p", { staticClass: "price yellow mt2" }, [
                          _vm._v(
                            "€" + _vm._s(_vm.packProduct.defaultPrice) + ",-"
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.isMobile && _vm.packBanner !== undefined
      ? _c(
          "a",
          {
            staticClass: "color-samples-banner",
            style: { backgroundImage: "url(" + _vm.packBanner.image + ")" },
            attrs: { href: "#samples-variants" },
          },
          [
            _vm.packBanner.title
              ? _c("p", { staticClass: "color-samples-banner-title serif" }, [
                  _vm._v(_vm._s(_vm.packBanner.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.packBanner.description
              ? _c("p", { staticClass: "color-samples-banner-description" }, [
                  _vm._v(_vm._s(_vm.packBanner.description)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.packBanner.price
              ? _c("p", { staticClass: "price yellow" }, [
                  _vm._v("€" + _vm._s(_vm.packBanner.price) + ",-"),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isMobile
      ? _c("section", { staticClass: "color-samples-color-variants" }, [
          _c("h2", { staticClass: "color-samples__list-title serif" }, [
            _vm._v(_vm._s(_vm._translate("Select sample per piece"))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "color-samples__list" },
            _vm._l(this.colors, function (color, handle) {
              return _c("li", [
                _c("input", {
                  attrs: {
                    type: "radio",
                    name: "colors",
                    id: _vm._translate(color.name),
                  },
                  domProps: {
                    checked:
                      _vm.selectedColor !== undefined &&
                      _vm.selectedColor.handle === handle,
                    value: handle,
                  },
                  on: {
                    change: function ($event) {
                      _vm.changeColor(color.name, handle)
                      _vm.scrollElementInView("color-samples", 300, "smooth")
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: _vm._translate(color.name) } }, [
                  _c("div", { staticClass: "color-outer-circle" }, [
                    _c("div", {
                      staticClass: "color-inner-circle",
                      style: { backgroundColor: color.hex },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm._translate(color.name)))]),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "color-samples-main-content",
        attrs: { id: "samples-variants" },
      },
      [
        _c("div", { staticClass: "flex" }, [
          !_vm.isMobile
            ? _c("div", { staticClass: "color-samples-sidebar" }, [
                _c("div", { staticClass: "color-samples-sidebar-section" }, [
                  _c(
                    "p",
                    { staticClass: "color-samples-sidebar-title serif" },
                    [_vm._v(_vm._s(_vm._translate("Finish")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "color-samples-filters-materials" },
                    _vm._l(this.materials, function (name, handle) {
                      return _c(
                        "li",
                        { staticClass: "color-samples-filters-color-item" },
                        [
                          _c("input", {
                            attrs: {
                              type: "radio",
                              name: "sidebar-materials",
                              id: "sidebar-" + handle,
                            },
                            domProps: { value: handle },
                            on: {
                              change: function ($event) {
                                return _vm.changeMaterial(name, handle)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "sidebar-" + handle } }, [
                            _c("span", { staticClass: "radio-circle" }),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._translate(name)) +
                                "\n              "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "color-samples-sidebar-section" }, [
                  _c(
                    "p",
                    { staticClass: "color-samples-sidebar-title serif" },
                    [_vm._v(_vm._s(_vm._translate("Colors")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "color-samples-filters-colors" },
                    _vm._l(this.colors, function (color, handle) {
                      return _c(
                        "li",
                        { staticClass: "color-samples-filters-colors-item" },
                        [
                          _c("input", {
                            attrs: {
                              type: "radio",
                              name: "sidebar-colors",
                              id: "sidebar-" + color.name,
                            },
                            domProps: {
                              checked:
                                _vm.selectedColor !== undefined &&
                                _vm.selectedColor.handle === handle,
                              value: handle,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeColor(color.name, handle)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "sidebar-" + color.name } },
                            [
                              _c("span", {
                                staticClass: "color-circle",
                                style: { backgroundColor: color.hex },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm._translate(color.name))),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "color-samples-catalog" },
            [
              _c("color-samples-filters", {
                on: {
                  resetMaterialFilter: _vm.uncheckMaterialInputs,
                  resetColorFilter: _vm.uncheckColorInputs,
                },
              }),
              _vm._v(" "),
              _c("color-samples-products", {
                attrs: { products: _vm.packColors },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }