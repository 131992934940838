<template>
  <div class="color-samples-filters-settings">
    <h2  class="color-samples-search-title serif" v-if="$parent.isMobile">{{ _translate('Select sample per piece') }}</h2>
    <div class="color-samples-search flex flex-column">
      <input type="text" name="search" :placeholder="_translate('Find RAL color')" @keyup="searchColorSamples">
      <div v-if="$parent.isMobile" class="color-samples-filters-mobile flex justify-between items-center">
        <p class="serif f3">{{ _translate('Colors') }}</p>
        <ul class="color-samples-filters-colors">
          <li class="color-samples-filters-colors-item" v-for="(color, handle) in this.$parent.colors">
            <input type="radio"
                   name="colors"
                   :id="'filters-' + color.name"
                   @change="changeColor(color.name, handle); scrollElementInView('color-samples', 260, 'smooth');"
                   :checked="selectedColor !== undefined && selectedColor.handle === handle"
                   :value="handle">
            <label :for="'filters-' + color.name">
              <div class="color-outer-circle">
                <div class="color-inner-circle" :style="{backgroundColor: color.hex}"></div>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div v-if="$parent.isMobile" class="color-samples-filters-mobile flex justify-between items-center">
        <p class="serif f3">{{ _translate('Finish') }}</p>
        <ul class="color-samples-filters-materials">
          <li class="color-samples-filters-color-item" v-for="(name, handle) in this.$parent.materials">
            <input type="radio"
                   name="materials"
                   :id="'filters-' + handle"
                   :data-name="name"
                   :checked="selectedMaterial !== undefined && selectedMaterial.handle === handle"
                   @change="changeMaterial(name, handle)"
                   :value="handle">
            <label :for="'filters-' + handle">
              <span class="radio-circle"></span>
              {{ name }}
            </label>
          </li>
        </ul>
      </div>

      <div class="color-samples-filters" v-if="selectedMaterial || selectedColor">
        <h3 class="color-samples-filters-title serif">
          Filters
        </h3>
        <div class="selected-filters">
          <button
              v-if="selectedMaterial"
              @click="removeMaterialFilter"
          >
            <span>{{ _translate(selectedMaterial.name) }}</span>
            <svg class="close-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.5L1.5 0L5 3.5L8.5 0L10 1.5L6.5 5L10 8.5L8.5 10L5 6.5L1.5 10L0 8.5L3.5 5" fill="#B2B2B2"/>
            </svg>
          </button>
          <button
              v-if="selectedColor"
              @click="removeColorFilter"
          >
            <span>{{ _translate(selectedColor.name) }}</span>
            <svg class="close-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.5L1.5 0L5 3.5L8.5 0L10 1.5L6.5 5L10 8.5L8.5 10L5 6.5L1.5 10L0 8.5L3.5 5" fill="#B2B2B2"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorSamplesProducts from "./ColorSamplesProducts.vue";
import productColors from "../ProductColors.vue";
import ExpandableText from '../ExpandableText'
import store from "../../store";
import anime from "animejs"
import {translatesMixin} from "../../mixins/translatesMixin";
import {commonMixin} from "../../mixins/commonMixin";

export default {
  name: "ColorSamplesFilters",
  mixins: [translatesMixin, commonMixin],
  components: {
    ExpandableText,
    ColorSamplesProducts,
  },
  data() {
    return {
      state: store.state,
    }
  },
  props: {
  },
  computed: {
    selectedMaterial() {
      return this.state.selectedOptions.sampleMaterial;
    },
    selectedColor() {
      return this.state.selectedOptions.sampleColor;
    },
  },
  methods: {
    searchColorSamples(e) {
      const searchValue = e.target.value;
      if (searchValue.length === 0) {
        store.setSelectedOption('searchValue', null)
      }
      store.setSelectedOption('searchValue', searchValue)
    },
    changeColor(colorName, colorHandle) {
      store.setSelectedOption('sampleColor', {
        'name': colorName,
        'handle': colorHandle,
      })
    },
    changeMaterial(materialName, materialHandle) {
      store.setSelectedOption('sampleMaterial', {
        'name': materialName,
        'handle': materialHandle,
      })
    },
    removeMaterialFilter() {
      store.setSelectedOption('sampleMaterial', null)
      this.material = null
      this.$emit('resetMaterialFilter')
    },
    removeColorFilter() {
      store.setSelectedOption('sampleColor', null)
      this.color = null
      this.$emit('resetColorFilter')
    },
  },
}
</script>

<style scoped lang="scss">
</style>