var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "product__colors" },
            _vm._l(_vm.colors, function (color) {
              return _c(
                "a",
                {
                  key: color.id,
                  staticClass: "product__color",
                  attrs: { href: color.url, title: color.title },
                  on: {
                    click: function ($event) {
                      return _vm.chooseColor(color)
                    },
                  },
                },
                [
                  _vm.selectedColor
                    ? _c("product-color", {
                        attrs: {
                          color: color,
                          "info-text": _vm.computedInfo(color),
                          "model-value": _vm.selectedColor,
                          value: color.id,
                        },
                        on: { click: _vm.chooseColor },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedColor && _vm.isMobile
        ? _c(
            "color-dropdown",
            {
              attrs: {
                "selected-color": _vm.selectedColor,
                product: _vm.product,
                note: _vm._translate(
                  "Choose a custom color during the ordering process"
                ),
              },
            },
            _vm._l(_vm.colors, function (color, index) {
              return _c(
                "a",
                {
                  key: color.id,
                  staticClass: "product__color",
                  attrs: { href: color.url, title: color.title },
                  on: {
                    click: function ($event) {
                      return _vm.chooseColor(color)
                    },
                  },
                },
                [
                  _vm.selectedColor
                    ? _c("product-color", {
                        attrs: {
                          color: color,
                          "info-text": _vm.computedInfo(color),
                          "model-value": _vm.selectedColor,
                          value: color.id,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  index === _vm.colors.length - 1
                    ? _c("sup", { staticClass: "product__asterisk" }, [
                        _vm._v("*"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }