var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "color-radio__button color-radio__button--active" },
    [
      _c("input", {
        staticClass: "color-radio__input",
        class: { checked: _vm.color.id === _vm.modelValue.id },
        attrs: {
          type: "radio",
          name: "colors",
          disabled: _vm.$parent.isMobile,
        },
        on: { input: _vm.setColor },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "color-radio__helper",
          class:
            _vm.color.vSkuModificator === "CC"
              ? "color-radio__helper--last"
              : "",
          style: { color: _vm.color.color },
        },
        [
          _c(
            "span",
            {
              staticClass: "color-radio__hint",
              class:
                _vm.color.vSkuModificator === "CC"
                  ? "color-radio__hint--others"
                  : "",
            },
            [
              _c("span", {
                staticClass: "color-radio__label",
                style: { "background-color": _vm.color.color },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "color-radio__title" }, [
                _vm._v(_vm._s(_vm.color.title)),
              ]),
              _vm._v(" "),
              _vm.color.colorCode
                ? _c("span", { staticClass: "color-radio__code" }, [
                    _vm._v(_vm._s(_vm.color.colorCode)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoText
                ? _c("span", { staticClass: "color-radio__info" }, [
                    _vm._v(_vm._s(_vm.infoText)),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.note && _vm.color.vSkuModificator === "CC"
        ? _c("span", { staticClass: "color-radio__note" }, [
            _vm._v(_vm._s(_vm.note)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }