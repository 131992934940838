var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__description" },
    [
      _vm.shortDescription
        ? [
            _c("div", {
              staticClass: "product__description--list",
              domProps: { innerHTML: _vm._s(_vm.computedHtml) },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "sans-serif f5 lh-copy bg-transparent bn outline-0 pointer product__show-more",
                attrs: { title: _vm._translate(_vm.buttonLabel) },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggle.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm._translate(_vm.buttonLabel)))]
            ),
          ]
        : [
            _c("expandable-text", {
              attrs: {
                "is-description": true,
                "description-text": _vm.descriptionText.fullText,
                "btn-label-collapsed": _vm._translate("Read more"),
                "btn-label-expanded": _vm._translate("Read less"),
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }