var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "radio__button", class: { short: _vm.short } },
    [
      _c("input", {
        staticClass: "radio__input",
        attrs: { type: "radio" },
        domProps: { checked: _vm.isChecked, value: _vm.value },
        on: {
          change: function ($event) {
            return _vm.$emit("change", _vm.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "radio__helper" }, [
        _vm._v("\n    " + _vm._s(_vm._translate(_vm.title)) + "\n    "),
        _vm.isChecked && _vm.isClearable
          ? _c(
              "span",
              {
                staticClass: "radio__clear",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("clear")
                  },
                },
              },
              [_vm._v("x")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isNew
          ? _c("span", { staticClass: "radio__indicator_new" }, [_vm._v("new")])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }