var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__list_shown" },
    [
      _vm.configurations
        ? [
            _vm.filteredProducts.length > 0
              ? _vm._l(_vm.filteredProducts, function (product, index) {
                  return _c(
                    "main-product-component",
                    {
                      key: index,
                      attrs: {
                        configuration: _vm.getConfiguration(product),
                        images: _vm.getImages(product),
                        colors: _vm.getColors(product),
                        product: product,
                      },
                    },
                    [
                      _c("div", { staticClass: "dark-gray" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("div", { staticClass: "w-50-l" }, [
                          _c("h3", { staticClass: "product__title serif" }, [
                            _vm._v(_vm._s(product.title)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "product__modification" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._translate("Popular")) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                })
              : _c("div", { staticClass: "product__info_warning" }, [
                  _vm._v(
                    _vm._s(
                      _vm._translate(
                        "Please adjust depth, length or add-ons to see available models"
                      )
                    )
                  ),
                ]),
          ]
        : _c("div", { staticClass: "product__loader" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }