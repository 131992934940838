var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "accessory__item",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.addToCart.apply(null, arguments)
        },
      },
    },
    [
      _c("input", {
        attrs: {
          type: "hidden",
          name: "action",
          value: "commerce/cart/update-cart",
        },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "hidden",
          name: "purchasables[" + _vm.item.id + "][id]",
          id: "inputPurchasableId",
        },
        domProps: { value: _vm.currentVariant.id },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "hidden",
          name: "purchasables[" + _vm.item.id + "][qty]",
        },
        domProps: { value: _vm.count },
      }),
      _vm._v(" "),
      _vm.addition
        ? _c("input", {
            attrs: {
              type: "hidden",
              name: "purchasables[" + _vm.item.id + "][options][addition]",
            },
            domProps: { value: _vm.addition },
          })
        : _vm._e(),
      _vm._v(" "),
      this.color && this.color.vSkuModificator === "CC"
        ? _c("input", {
            attrs: {
              type: "hidden",
              name:
                "purchasables[" +
                _vm.item.id +
                "][options][ralColorCodeManual]",
            },
            domProps: { value: this.color.colorCode },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "accessory__image",
          attrs: { "data-image": _vm.item.type },
        },
        [_c("img", { attrs: { src: _vm.computedImage, alt: _vm.item.slug } })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "accessory__data" }, [
        _c("div", { staticClass: "accessory__title" }, [
          _vm._v(_vm._s(_vm.computedTitle)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "accessory__colors" },
          [
            _c("color-select-component", {
              attrs: {
                "extended-color": _vm.extendedColor,
                "available-colors": _vm.availableColors,
                placeholder: _vm._translate("Select color"),
              },
              model: {
                value: _vm.color,
                callback: function ($$v) {
                  _vm.color = $$v
                },
                expression: "color",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        false
          ? _c(
              "div",
              { staticClass: "accessory__colors" },
              _vm._l(_vm.colors, function (color) {
                return _c(
                  "div",
                  {
                    key: color.id,
                    staticClass: "product__color",
                    attrs: { title: color.title },
                    on: {
                      click: function ($event) {
                        return _vm.store.setSelectedOption("v_color", color)
                      },
                    },
                  },
                  [
                    _vm.selectedColor
                      ? _c("product-color", {
                          attrs: {
                            color: color,
                            "model-value": _vm.selectedColor,
                            value: color.id,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "accessory__actions" }, [
        _c("div", { staticClass: "accessory__counter" }, [
          _c("div", {
            staticClass:
              "accessory__change-count accessory__change-count_minus",
            on: {
              click: function ($event) {
                return _vm.changeCount(-1)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "accessory__count" }, [
            _vm._v(_vm._s(_vm.count)),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "accessory__change-count accessory__change-count_plus",
            on: {
              click: function ($event) {
                return _vm.changeCount(1)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "accessory__cart" }, [
          _c("div", { staticClass: "accessory__cost" }, [
            _vm._v("+" + _vm._s(_vm._f("toEur")(_vm.computedPrice))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "accessory__add-to-cart",
              attrs: { type: "submit", disabled: _vm.count === 0 },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._translate("Add to cart")) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }