<template>
  <form class="accessory__item"
        @submit.prevent="addToCart"
        ref="form">
    <input type="hidden" name="action" value="commerce/cart/update-cart">
    <input type="hidden" :name="'purchasables['+item.id+'][id]'" :value="currentVariant.id"
           id="inputPurchasableId">
    <input type="hidden" :name="'purchasables['+item.id+'][qty]'" :value="count">
    <input v-if="addition" type="hidden" :name="'purchasables['+item.id+'][options][addition]'" :value="addition">
    <input v-if="this.color && this.color.vSkuModificator === 'CC'"
           type="hidden"
           :name="'purchasables['+item.id+'][options][ralColorCodeManual]'"
           :value="this.color.colorCode"
    >

    <div class="accessory__image" :data-image="item.type">
      <img :src="computedImage" :alt="item.slug">
    </div>
    <div class="accessory__data">
      <div class="accessory__title">{{ computedTitle }}</div>
      <div class="accessory__colors">
        <color-select-component :extended-color="extendedColor"
                                :available-colors="availableColors"
                                v-model="color"
                                :placeholder="_translate('Select color')"/>
      </div>
      <div class="accessory__colors" v-if="false">
        <div v-for="color in colors"
           :key="color.id"
           :title="color.title"
           @click="store.setSelectedOption('v_color', color)"
           class="product__color">
          <product-color :color="color"
                         v-if="selectedColor"
                         :model-value="selectedColor"
                         :value="color.id">
          </product-color>
        </div>
      </div>
    </div>
    <div class="accessory__actions">
      <div class="accessory__counter">
        <div class="accessory__change-count accessory__change-count_minus"
             @click="changeCount(-1)"></div>
        <div class="accessory__count">{{ count }}</div>
        <div class="accessory__change-count accessory__change-count_plus"
             @click="changeCount(1)"></div>
      </div>
      <div class="accessory__cart">
        <div class="accessory__cost">+{{ computedPrice | toEur }}</div>
        <button type="submit" class="accessory__add-to-cart" :disabled="count === 0">
          {{ _translate("Add to cart") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import ColorRadioButton from "./ColorRadioButton";
import store from '../store'
import ColorSelectComponent from './ColorSelectComponent.vue';
import axios from 'axios';

export default {
  name: "AccessoryComponent",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      availableColors: [],
      count: 0,
      color: null,
      ralColors: [],
    };
  },
  components: {
    ColorSelectComponent,
    ColorRadioButton,
  },
  methods: {
    _translate (key) { return store._translates(key)},
    changeCount(inc = 1) {
      if (this.count + inc >= 0) {
        this.count += inc
      }
    },
    addToCart() {
      const form = this.$refs.form
      let data = new FormData(form)
      data.append(store.state.csrf.tokenName, store.state.csrf.tokenValue)
      data.append(`purchasables[${this.item.id}][options][v_color]`,
        JSON.stringify({
          id: this.currentVariant.vcolorsCategory[0].id,
          price: this.color.vPriceModificator,
        })
      );

      const settings = {
        url: '/',
        data,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')
      axios(settings)
        .then(() => {
          axios
              .get('/' + locale + '/api/v1/cart.json')
              .then(response => {
                const data = response.data.data[0]
                store.setCartAction(data)
              })
        });
    },
  },
  mounted() {
    this.color = this.extendedColor;
    const locale = document.documentElement.getAttribute('lang')
    axios.get('/' + locale + '/ralcolors.json')
        .then((response) => {
          this.ralColors = response.data
        })
  },
  computed:{
    addition() {
      if (this.currentVariant.vcolorsCategory[0].id === 58449) {
        return JSON.stringify({
          v_color: this.extendedColor.colorCode
        })
      }

      return null
    },
    computedTitle() {
      const pattern = /\{color\.title\}/;
      const title = this.color ? this.color.title : '';
      return this.item.title.replace(pattern, title);
    },
    computedPrice() {
      const additionalPrice = this.color ? this.color.vPriceModificator : 0;

      return (+additionalPrice + +this.item.defaultPrice) * this.count;
    },
    extendedColor() {
      const lastShelve = store.getLastShelve()
      if (lastShelve.color !== undefined && lastShelve.color.vSkuModificator === 'CC') {
        const customColorInstance = store.getLastShelve().color
        const colorCode = lastShelve.options['Color selected'] ? lastShelve.options['Color selected'] : ''
        if (!colorCode) {
          return lastShelve.color
        }

        const selectedColor = this.ralColors.find((color) => {
          let isRal = color.RAL.includes('RAL')
          if (isRal) {
            return color.RAL.replace(/\s/g, '') === colorCode
          }

          return color.RAL === colorCode
        })

        if (!selectedColor) {
          return lastShelve.color
        }

        customColorInstance.color = selectedColor.HEX
        customColorInstance.colorCode = colorCode

        return customColorInstance
      }

      return lastShelve.color;
    },
    computedImage() {
      return this.item.images.hasOwnProperty('default')
          ? this.item.images.default[0]
          : this.item.images[Object.keys(this.item.images)[0]][0];
    },
    colors () {
      // there need be different color state, for all accessories
      // Something like
      // this.item.colors
      return store.state.filters.v_color.slice(0, 4);
    },
    selectedColor() {
      // there need be different
      // v_color for accessory, not for shelf
      return store.getSelectedOption('v_color');
    },
    currentVariant() {
      let c = this.color ? this.color : this.extendedColor
      this.availableColors = this.item.variants.map((variant) => variant.vcolorsCategory[0])
      let currentVariant = this.item.variants.find(variant => variant.sku.includes(c.vSkuModificator))

      if (typeof currentVariant === 'undefined') {
        currentVariant = this.item.variants.find(variant => variant.vcolorsCategory[0].vSkuModificator === 'CC')
      }

      if (typeof currentVariant === 'undefined') {
        currentVariant = this.item.variants.find(variant => variant.isDefault)
      }

      return currentVariant
    }
  },
}
</script>

<style lang="scss">
  .accessory {
    &__counter {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 16px;
    }

    &__item {
      margin-bottom: 2.5rem;
    }

    &__image {
      @media screen and (max-width: 60em) {
        margin-bottom: 2rem;
      }

      background-color: #181818;
      width: 100%;
      height: 8rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &__add-to-cart {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffd600;
      cursor: pointer;
      text-transform: lowercase;
      background: transparent;
      border: none;
      font-family: FuturaPT, sans-serif;

      &:disabled {
        opacity: 0.3;
      }
    }

    &__actions {
      margin-top: 1rem;
    }

    &__colors {
      display: flex;
      gap: 21px;
      flex-shrink: 0;

      @media screen and (max-width: 60em) {
        gap: 0.3125rem;
      }
    }

    &__cost {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
    }

    &__title {
      font-family: Ladislav,serif;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
    }

    &__change-count {
      opacity: 0.7;
      color: #ffd700;
      transition: 0.3s;
      cursor: pointer;
      width: 12px;
      height: 12px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &_minus {
        background-image: url("/build/img/minus.svg");
      }

      &_plus {
        background-image: url("/build/img/plus.svg");
      }

      &:hover {
        opacity: 1;
      }
    }

    &__cart {
      gap: 24px;
    }

    &__cart,
    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__data {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
  }
</style>
