var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "checkbox__button" }, [
    _c("input", {
      staticClass: "checkbox__input",
      attrs: { type: "checkbox" },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "checkbox__helper" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }