var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "product__v_finish-header" }, [
      _vm._v(_vm._s(_vm._translate("Finish"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product__v_finish" },
      _vm._l(_vm.items, function (finish) {
        return _c(
          "div",
          {
            key: finish.id,
            staticClass: "product__finish",
            class: { product__finish_active: _vm.isActive(finish.id) },
            on: {
              click: function ($event) {
                return _vm.changeValue(finish)
              },
            },
          },
          [
            _c("div", {
              staticClass: "product__finish-image expand__option",
              style: _vm.computedStyle(finish),
            }),
            _vm._v(" "),
            _c("div", { staticClass: "product__finish-title" }, [
              _vm._v(_vm._s(finish.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product__finish-cost" }, [
              _vm._v(_vm._s(_vm._f("toEur")(finish.vPriceModificator))),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }