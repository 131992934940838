var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { css: false }, on: { enter: _vm.enter, leave: _vm.leave } },
    [
      _vm.sharedState.showIntroVideo
        ? _c("div", { staticClass: "fixed z-max bg-black absolute--fill" }, [
            _c(
              "button",
              {
                staticClass:
                  "bg-transparent pointer bw0 absolute top-2 right-2 outline-0 z-3",
                on: { click: _vm.close },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "25",
                      height: "25",
                      viewBox: "0 0 25 25",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          fill: "#FFFFFF",
                          "fill-rule": "evenodd",
                          transform: "rotate(45 19.328 9.672)",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: { width: "1", height: "33", x: "16" },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            width: "1",
                            height: "33",
                            x: "16",
                            transform: "rotate(-90 16.5 16.5)",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "absolute absolute--fill flex justify-center items-center",
              },
              [
                _c("iframe", {
                  staticClass: "w-100 vh-100",
                  attrs: {
                    src: `https://www.youtube.com/embed/${_vm.privateState.videoId}?autoplay=1&showinfo=0&modestbranding=1&rel=0`,
                    frameborder: "0",
                    allowfullscreen: "",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }