var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-component" }, [
    _c(
      "div",
      {
        staticClass: "input-component__label",
        class: { "input-component__label_shown": _vm.value },
      },
      [_vm._v(_vm._s(_vm.computedPlaceholder))]
    ),
    _vm._v(" "),
    _c(
      "input",
      _vm._b(
        {
          staticClass: "input-component__input",
          class: { invalid: _vm.invalid, success: _vm.success },
          attrs: { required: _vm.required },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        "input",
        {
          value: _vm.value,
          type: _vm.type,
          name: _vm.name,
          placeholder: _vm.computedPlaceholder,
        },
        false
      )
    ),
    _vm._v(" "),
    _vm.$slots
      ? _c(
          "div",
          { staticClass: "input-component__error-text" },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }