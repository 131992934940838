var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "ral-colors-palette--table js-ral-colors-palette--table" },
    _vm._l(this.ralColors, function (color, key) {
      return _c(
        "li",
        {
          style: { backgroundColor: color.HEX },
          on: {
            click: function ($event) {
              return _vm.selectCustomColor(color.RAL)
            },
          },
        },
        [
          _c(
            "label",
            {
              class: { active: color.RAL === _vm.selectedColor },
              attrs: { for: "ralColorCode-" + key },
            },
            [
              _c("span", { class: _vm.isLight(color) ? "dark" : "light" }, [
                _vm._v(_vm._s(color.RAL)),
              ]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }