var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product__v_color" },
    [
      [
        _c(
          "div",
          { staticClass: "product__v_color-wrapper" },
          _vm._l(_vm.colors, function (color, index) {
            return _c("color-radio-button", {
              key: color.id,
              attrs: {
                color: color,
                "model-value": _vm.state.selectedOptions.v_color,
              },
              on: {
                change: function ($event) {
                  return _vm.changeValue(color, index)
                },
              },
            })
          }),
          1
        ),
      ],
      _vm._v(" "),
      _vm.custom
        ? _c("div", { staticClass: "product__custom-color" }, [
            _c("div", { staticClass: "product__custom-color-label" }, [
              _vm._v(_vm._s(_vm._translate("Custom color"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mb2 js-ral-colors-palette--input flex",
                class: _vm.customColor ? "half-width" : "full-width",
              },
              [
                _c(
                  "div",
                  { staticClass: "product__custom-color-input-wrapper flex" },
                  [
                    _vm.customColor
                      ? _c(
                          "p",
                          {
                            style: {
                              backgroundColor: _vm.getHex(_vm.customColor),
                            },
                          },
                          [_vm._v("color")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("viempie-field", {
                      ref: "paletteColor",
                      attrs: {
                        styles:
                          "padding: 4px 0.5rem;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;border: 0;",
                        type: "text",
                        name:
                          "purchasables[" +
                          _vm.product.id +
                          "][options][ralColorCodeManual]",
                        id: "ral-color-code",
                        label: _vm._translate("RAL color code"),
                        placeholder: _vm._translate("Please select a colour"),
                        validation: "required|min:4",
                        initialvalue: _vm.customColor,
                        readonly: "true",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeCustomVal($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.getCustomSku
                      ? _c("input", {
                          attrs: {
                            type: "hidden",
                            name:
                              "purchasables[" +
                              _vm.product.id +
                              "][options][ralColorSku]",
                          },
                          domProps: { value: _vm.getCustomSku },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.customColor
                  ? _c(
                      "div",
                      { staticClass: "product__v_color-custom-color-price" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm._f("toEur")(_vm.getPrice()))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.customColor
                  ? _c(
                      "div",
                      { staticClass: "product__v_color-custom-color-wrapper" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "product__v_color-custom-color product__v_color-custom-color_hide",
                            attrs: { type: "button" },
                            on: { click: _vm.removeCustom },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._translate("Remove")) + "\n        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "hyphenate mb4 js-ral-colors-palette",
                class: _vm.customColor ? "dn" : "",
              },
              [
                _c("ul", {
                  staticClass:
                    "ral-colors-palette--table js-ral-colors-palette--table",
                }),
              ]
            ),
            _vm._v(" "),
            _vm.samplePackUrl
              ? _c("div", [
                  _c("span", { staticClass: "color_samples_reference" }, [
                    _vm._v(
                      _vm._s(_vm._translate("In doubt on color or finish?")) +
                        " "
                    ),
                    _c("a", { attrs: { href: _vm.samplePackUrl } }, [
                      _vm._v(_vm._s(_vm._translate("Order a color sample"))),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }