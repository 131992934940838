<template>
  <div class="product__v_color">
    <template>
      <div class="product__v_color-wrapper">
        <color-radio-button v-for="(color, index) in colors"
                            :color="color"
                            :key="color.id"
                            :model-value="state.selectedOptions.v_color"
                            @change="changeValue(color, index)"/>
      </div>
    </template>
    <div v-if="custom" class="product__custom-color">
      <div class="product__custom-color-label">{{ _translate('Custom color') }}</div>
      <div class="mb2 js-ral-colors-palette--input flex" :class="customColor ? 'half-width' :'full-width'">
        <div class="product__custom-color-input-wrapper flex">
          <p v-if="customColor" :style="{backgroundColor: getHex(customColor)}">color</p>
          <viempie-field
              styles="padding: 4px 0.5rem;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;border: 0;"
              type="text"
              :name="'purchasables[' + product.id + '][options][ralColorCodeManual]'"
              id="ral-color-code"
              :label="_translate('RAL color code')"
              :placeholder="_translate('Please select a colour')"
              validation="required|min:4"
              ref="paletteColor"
              @change="changeCustomVal($event)"
              :initialvalue="customColor"
              readonly="true"
          ></viempie-field>
          <input
              v-if="getCustomSku"
              type="hidden"
              :name="'purchasables[' + product.id + '][options][ralColorSku]'"
              :value="getCustomSku"
          >
        </div>
        <div v-if="customColor" class="product__v_color-custom-color-price"><p>{{ getPrice()|toEur() }}</p></div>
        <div v-if="customColor" class="product__v_color-custom-color-wrapper">
          <button type="button"
                  class="product__v_color-custom-color product__v_color-custom-color_hide"
                  @click="removeCustom">{{ _translate('Remove') }}
          </button>
        </div>
      </div>
      <div class="hyphenate mb4 js-ral-colors-palette" :class="customColor ? 'dn' : ''">
        <ul class="ral-colors-palette--table js-ral-colors-palette--table"></ul>
      </div>
      <div v-if="samplePackUrl">
        <span class="color_samples_reference">{{ _translate('In doubt on color or finish?') }} <a :href="samplePackUrl">{{ _translate('Order a color sample') }}</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import ColorRadioButton from './ColorRadioButton.vue'
import ViempieField from '../components/ViempieField'
import RalColorPalette from '../components/RalColorPalette'

import store from '../store'
import axios from "axios";

export default {
  name: 'ProductColors',
  components: {
    ColorRadioButton,
    ViempieField,
  },
  props: {
    initColor: {
      type: Object,
      required: true
    },
    colors: {
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    samplePackUrl: {
      type: String,
      require: false,
    }
  },
  mounted () {
    const customColorSKU = 'CC';
    if (this.initColor.vSkuModificator === customColorSKU) {
      this.changeCustom();
    }
    const locale = document.documentElement.getAttribute('lang')
    axios.get('/' + locale + '/ralcolors.json')
        .then((response) => this.ralColors = response.data)

    RalColorPalette({
      initValue: this.customColor,
      el: '.js-ral-colors-palette',
      inputField: '.js-ral-colors-palette--input input',
      ralTable: '.js-ral-colors-palette--table',
      vm: this
    })
  },
  data: () => ({
    ralColors: [],
    state: store.state,
    custom: !!store.state.additionInfo.v_color,
    customColor: store.state.additionInfo.v_color ? store.state.additionInfo.v_color : '',
  }),
  watch: {
    customColor (val) {
      this.$emit('changeAdditionInfo', 'v_color', val)
    }
  },
  beforeDestroy () {
    if (this.custom && this.customColor === '') {
      this.$emit('costChange', 'v_color', this.state.filters.v_color[0])
      this.$emit('changeAdditionInfo', 'v_color', null)
    }
  },
  methods: {
    _translate (key) { return store._translates(key)},
    changeCustomVal (value) {
      this.customColor = value;
    },
    changeCustom () {
      const preselectedCustomColor = store.getAdditionInfo('v_color');
      if (preselectedCustomColor) {
        this.custom = true;
        this.customColor = preselectedCustomColor;
      } else {
        this.custom = !this.custom
      }

      if (!this.custom) {
        this.$emit('costChange', 'v_color', this.state.filters.v_color[0])
        this.$emit('changeAdditionInfo', 'v_color', null)
      } else {
        setTimeout(() => {
          RalColorPalette({
            initValue: this.customColor,
            el: '.js-ral-colors-palette',
            inputField: '.js-ral-colors-palette--input input',
            ralTable: '.js-ral-colors-palette--table',
            vm: this
          })
        }, 300)
        this.$emit('costChange', 'v_color', this.state.filters.v_color.find(color => color.vSkuModificator === 'CC'))
      }
    },
    removeCustom() {
      this.customColor = '';
      this.$emit('costChange', 'v_color', this.state.filters.v_color.find(color => color.vSkuModificator === 'CC'))
      this.$emit('changeAdditionInfo', 'v_color', null)
      this.$refs.paletteColor.currentValue = ''
      this.changeCustomVal('')
    },
    changeValue (value) {
      if (value.vSkuModificator === 'CC') {
        this.customColor = '';
        this.changeCustom();
      }
      else {
        this.custom = false;
        this.customColor = '';
      }
      this.$emit('costChange', 'v_color', value);
      this.$emit('changeAdditionInfo', 'v_color', null);
    },
    getHex (value) {
      // Remove parentheses & content inside
      value = value.replace(/ *\([^)]*\) */g, "");
      let HEX = ''
      const item = this.ralColors.filter((item) => item['RAL'].replaceAll(/\s/g,'') === value.replace(/\s/g, ""));
      if (item !== undefined && item !== 'undefined' && item.length !== 0) {
        HEX = item[0].HEX;
      }
      return HEX;
    },
    getPrice () {
      const price = this.colors.filter(function (item) {
        return item.colorCode === null
      })
      if (price !== undefined && price[0] !== undefined) return price[0].vPriceModificator
      else return 0
    },
  },
  computed: {
    getSelectedColor () {
      let value = this.customColor.replace(/ *\([^)]*\) */g, "");
      if (!value) {
        return undefined;
      }

      const item = this.ralColors.filter((item) => item['RAL'].replaceAll(/\s/g,'') === value.replace(/\s/g, ""));
      if (!item) {
        return undefined;
      }

      return item[0];
    },
    getCustomSku () {
      let customSku = false;
      if (this.getSelectedColor && this.getSelectedColor.SKU !== undefined) {
        customSku = this.getSelectedColor.SKU;
      }

      return customSku;
    },
  },
}
</script>

<style scoped lang="scss">
.product__custom-color {
  margin-top: 2rem;
  &-label {
    font-family: 'Ladislav', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 0.5rem;
  }

    &-input-wrapper {
      width: 100%;
      padding: 0.5rem;
      border-radius: 2px;
      border: 2px solid #333;
      &.full-width {
        max-width: unset;
      }

      p {
        width: 64px;
        height: 32px;
        color: transparent;
      }

      input {
        padding: 4px 0.5rem;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border: 0;
      }
  }
}

.product__v_color {
  &-custom-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &-custom-color {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: none;

    &-price {
      display: flex;
      padding: 0 1rem;
      align-items: center;
    }
    &-cost {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 1rem;
    }

    &-field {
      border: none;
      outline: none;
      color: #fff;
      font-family: "FuturaPT", sans-serif;
    }

    &-type {
      color: #fff;
    }

    &-input {
      flex-grow: 1;
      border-radius: 2px;
      border: solid 2px #333333;
      padding: 12px 16px;
      display: flex;
      gap: 1rem;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-left: auto;
    }

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }

    &_hide {
      font-family: "FuturaPT", sans-serif;
      color: #ff5858;
      text-decoration: underline;
      &:hover, &:focus {
        color: #ff5858;
        text-decoration: none;
      }
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(11, 26px);
    justify-content: space-between;
    gap: 10px 0;
  }

  .color_samples_reference {
    color: #b2b2b2;
    a {
      text-decoration: underline;
    }
    a:hover {
      color: #ffd600;
    }
  }
}
</style>

<style lang="scss">
.product__custom-color label {
    display: none;
}
</style>
