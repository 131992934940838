var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "v-mid mw-none w-100 lazyload product__image",
    attrs: { src: _vm.src, "data-src": _vm.src, alt: _vm.title },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }