var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup" }, [
    _c("div", {
      staticClass: "popup__overlay",
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "popup__content" }, [
      _c(
        "button",
        {
          staticClass: "popup__close",
          attrs: { type: "button", title: "Close popup" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "svg",
            {
              staticClass: "v-mid",
              staticStyle: { fill: "currentColor" },
              attrs: { width: "12", height: "12" },
            },
            [
              _c("path", {
                attrs: {
                  d: "M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.title
        ? _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "popup__body",
        domProps: { innerHTML: _vm._s(_vm.html) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }