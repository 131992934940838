<template>
  <div class="filter">
    <div class="filter__buttons" v-if="isMobile">
      <template v-if="model.slug !== 'accessories'">
        <button class="filter__button filter__button--default"
                :class="{'filter__button--active': isSectionShow.length}"
                type="button"
                @click="openFilterSection('length')"
                v-if="isSectionClear.length">{{ _translate('Length') }}</button>
        <button class="filter__button"
                type="button"
                @click="clearFilterSection('length')"
                v-else>
          {{ store.getSelectedOption('v_len').vMinLen }} cm
          <span class="filter__button-clear">
            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5" fill="#B2B2B2" fill-rule="evenodd"/>
            </svg>
          </span>
        </button>
      </template>
      <button class="filter__button filter__button--default"
              :class="{'filter__button--active': isSectionShow.depth}"
              type="button"
              @click="openFilterSection('depth')"
              v-if="isSectionClear.depth">{{ _translate('Depth') }}</button>
      <button class="filter__button"
              type="button"
              @click="clearFilterSection('depth')"
              v-else>
        {{ store.getSelectedOption('v_depth').vDepth }} cm
        <span class="filter__button-clear">
          <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5" fill="#B2B2B2" fill-rule="evenodd"/>
          </svg>
        </span>
      </button>

      <template v-if="model.slug !== 'accessories'">
        <button class="filter__button filter__button--default"
                :class="{'filter__button--active': isSectionShow.color}"
                type="button"
                @click="openFilterSection('color')"
                v-if="isSectionClear.color">{{ _translate('Color') }}</button>
        <button class="filter__button"
                type="button"
                @click="clearFilterSection('color')"
                v-else>
          {{ store.getSelectedOption('v_color').title }}
          <span class="filter__button-clear">
            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5" fill="#B2B2B2" fill-rule="evenodd"/>
            </svg>
          </span>
        </button>
        <template v-if="!store.isMobile()">
          <button class="filter__button filter__button--default"
                  :class="{'filter__button--active': isSectionShow.cutouts}"
                  type="button"
                  @click="openFilterSection('cutouts')"
                  v-if="typeof store.getSelectedOption('v_cutouts').slug === 'undefined'">
            {{ _translate('Add-ons') }}
          </button>
          <button class="filter__button"
                  type="button"
                  @click="clearFilterSection('cutouts')"
                  v-else>
            {{ store.getSelectedOption('v_cutouts').title }}
            <span class="filter__button-clear">
              <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.5 1.5 0 5 3.5 8.5 0 10 1.5 6.5 5 10 8.5 8.5 10 5 6.5 1.5 10 0 8.5 3.5 5" fill="#B2B2B2" fill-rule="evenodd"/>
              </svg>
            </span>
          </button>
        </template>
      </template>
    </div>

    <FilterSection :title="_translate('Models')"
                   v-model="model.id"
                   property="v_model"
                   :options="availableModels"
                   :label="'models'"
                   ref="models"
                   :class="{'filter__wrapper--show': isSectionShow.models}"/>
    <FilterSection :title="_translate('Popular shelf lengths')"
                   v-if="lengths && model.slug !== 'accessories'"
                   class-name="mw300"
                   :is-clearable="true"
                   v-model="store.getSelectedOption('v_len').id"
                   property="v_len"
                   :custom-length-product="customLengthProduct"
                   :filters="filters"
                   :options="lengths"
                   :short="true"
                   :shown-items-count="6"
                   :label="'length'"
                   ref="length"
                   @clear="clearFilterSection('length')"
                   :class="{'filter__wrapper--show': isSectionShow.length}"/>
    <FilterSection :title="_translate('Shelf depth')"
                   v-if="depths"
                   :is-clearable="true"
                   property="v_depth"
                   v-model="store.getSelectedOption('v_depth').id"
                   :options="depths"
                   :label="'depth'"
                   @clear="clearFilterSection('depth')"
                   ref="depth"
                   :class="{'filter__wrapper--show': isSectionShow.depth}"/>
<!--    <FilterSection :title="_translate('Expand your shelf for')"-->
<!--                   v-if="filters.v_cutouts && model.slug !== 'accessories'"-->
<!--                   property="v_cutouts"-->
<!--                   :may-off="true"-->
<!--                   v-model="store.getSelectedOption('v_cutouts').id"-->
<!--                   :options="filters.v_cutouts"-->
<!--                   :label="'cutouts'"-->
<!--                   ref="cutouts"-->
<!--                   :class="{'filter__wrapper&#45;&#45;show': isSectionShow.cutouts}"/>-->
    <FilterSection :title="_translate('Color')"
                   type="color"
                   v-if="filters.v_color && model.slug !== 'accessories'"
                   property="v_color"
                   v-model="store.getSelectedOption('v_color')"
                   :options="store.state.filters.v_color"
                   :reset-button-text="_translate('Reset filter')"
                   :show-button-text="_translate('All colors')"
                   :shown-items-count="12"
                   :label="'color'"
                   ref="color"
                   :class="{'filter__wrapper--show': isSectionShow.color}"/>
  </div>
</template>

<script>
import FilterSection from './FilterSection.vue'
import store from '../store'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { addScrollableSelector } from 'scroll-lock/dist/scroll-lock.js';
let scrollElement = document.body

export default {
  name: 'FilterComponent',
  components: {
    FilterSection,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    links: {
      type: Object,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      store: store,
      models: this.filters.v_model,
      isSectionShow: {
        model: false,
        length: false,
        depth: false,
        cutouts: false,
        color: false
      },
      isSectionClear: {
        model: true,
        length: true,
        depth: true,
        cutouts: true,
        color: true
      },
      windowWidth: window.innerWidth,
    }
  },
  watch: {
    model (newVal, oldVal) {
      if (newVal && oldVal && newVal.id !== oldVal.id) {
        const url = window.location.origin
            + [...window.location.pathname.replace(/\/$/, '').split('/').slice(0, -1), newVal.slug].join('/')
        window.history.pushState(
            store.state.selectedOptions,
            window.document.title,
            url
        )
        const languages = Object.keys(this.links)
        for (let langIndex in languages) {
          const lang = languages[langIndex]
          const elems = document.querySelectorAll(`[hreflang="${lang}"]`);
          for (let i = 0; i < elems.length; i++) {
            elems[i].setAttribute('href', `${location.origin}/${lang}/${this.links[lang][`#${newVal.id}`]}${location.search}`)
          }
        }

        this.$parent.$refs.category__title.textContent = newVal.title
        this.$parent.$refs.category__subtitle.innerHTML = newVal.vDescription
      }
      // this.initLD()
    }
  },
  methods: {
    _translate (key) { return store._translates(key)},
    initLD () {
      const [length] = this.lengths
      const defaultSelectedLength = 75;
      let defaultSelectedDepth = 29.5;
      store.setSelectedOption('v_len', length)

      if (this.model.slug !== 'accessories') {
        const length = this.filters.v_len.find(length => length.vMinLen === defaultSelectedLength);
        store.setSelectedOption('v_len', length);
      } else {
        defaultSelectedDepth = 22
      }

      const depth = this.filters.v_depth.find(depth => depth.vDepth === defaultSelectedDepth);
      store.setSelectedOption('v_depth', depth);
    },
    openFilterSection(value) {
      setTimeout(() => {
        scrollElement = document.querySelector('.filter__wrapper--show .filter__options')
        disableBodyScroll(scrollElement);
      }, 0)
      switch (value) {
        case 'length':
          this.isSectionShow.length = true;
          break;
        case 'depth':
          this.isSectionShow.depth = true;
          break;
        case 'color':
          this.isSectionShow.color = true;
          break;
        case 'cutouts':
          this.isSectionShow.cutouts = true;
          break;
        default:
          break;
      }
    },
    changeFilterSection(value) {
      enableBodyScroll(scrollElement);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      switch (value) {
        case 'length':
          this.isSectionShow.length = false;
          this.isSectionClear.length = false;
          break;
        case 'depth':
          this.isSectionShow.depth = false;
          this.isSectionClear.depth = false;
          break;
        case 'color':
          this.isSectionShow.color = false;
          this.isSectionClear.color = false;
          break;
        case 'cutouts':
          this.isSectionShow.cutouts = false;
          this.isSectionClear.cutouts = false;
          break;
        default:
          break;
      }
    },
    clearFilterSection(value) {
      const defaultSelectedLength = 30;
      const defaultSelectedDepth = 12.5;

      switch (value) {
        case 'length':
          this.$refs.length.changeValue({});
          this.isSectionClear.length = true;

          const length = this.filters.v_len.find(length => length.vMinLen === defaultSelectedLength);
          store.setSelectedOption('v_len', {});

          break;
        case 'depth':
          this.$refs.depth.changeValue({});
          this.isSectionClear.depth = true;

          const depth = this.filters.v_depth.find(depth => depth.vDepth === defaultSelectedDepth);
          store.setSelectedOption('v_depth', {});

          break;
        case 'color':
          this.$refs.color.changeValue({});
          this.isSectionClear.color = true;

          const color = this.filters.v_color[0]
          store.setSelectedOption('v_color', color);

          break;
        case 'cutouts':
          this.$refs.cutouts.changeValue({});
          this.isSectionClear.cutouts = true;
          break;
        default:
          break;
      }
    },
    closeSection(value) {
      enableBodyScroll(scrollElement);
      switch (value) {
        case 'length':
          this.isSectionShow.length = false;
          break;
        case 'depth':
          this.isSectionShow.depth = false;
          break;
        case 'color':
          this.isSectionShow.color = false;
          break;
        case 'cutouts':
          this.isSectionShow.cutouts = false;
          break;
        default:
          break;
      }
    },
  },
  computed: {
    availableModels() {
      return this.filters.v_model.filter((model) => {
        if (model.uid !== '810be4fa-6f3f-44e8-8c85-c467080924c9') {
          return model
        }
      })
    },
    showAddons() {
      return typeof store.getSelectedOption('v_cutouts').slug === 'undefined'
        && !store.isMobile()
    },
    customLengthProduct() {
      return this.products.find((product) => product.sku === '018')
    },
    lengths () {
      if (this.model.slug !== 'accessories-2') {
        return store.getFixedLengths()
      } else {
        let lens = [], result = [], i = 0

        for (const accessory of this.products.filter(p => p.typeHandle === 'newAccessories')) {
          if (accessory.vMinLen && !lens.find(l => accessory.vMinLen === l)) {
            result.push({
              'id': `accL${++i}`,
              'title': `L${i}`,
              'slug': `l${accessory.vMinLen}`,
              'vMinLen': accessory.vMinLen,
              'vMaxLen': null,
              'vPriceModificator': 0
            })
            lens.push(accessory.vMinLen)
          }
        }
        return result
      }
    },
    depths () {
      let result = []
      switch (this.model.slug) {
        case 'room-divider':
          result = this.filters.v_depth.filter(dep => dep.vDepth > 16)
          break
        case 'accessories-2':
          let deps = [], i = 0
          for (const accessory of this.products.filter(p => p.typeHandle === 'newAccessories')) {
            if (accessory.vMinDepth && !deps.find(d => accessory.vMinDepth === d)) {
              result.push({
                'id': `accD${++i}`,
                'title': `D${i}`,
                'slug': `d${accessory.vMinDepth}`,
                'vDepth': accessory.vMinDepth,
                'vPriceModificator': 0
              })
              deps.push(accessory.vMinDepth)
            }
          }
          break
        default:
          result = this.filters.v_depth
          break
      }
      // if (!result.find(dep => dep.id === store.getSelectedOption('v_depth').id)) {
      //   store.setSelectedOption('v_depth', result[0])
      // }
      return result
    },
    model () { return store.getSelectedOption('v_model')},
    isMobile: function () {
      return this.windowWidth < 920;
    }
  },
  beforeMount () {
    store.setFilters(this.filters)
    Object.keys(this.filters).map(key => store.setSelectedOption(key, {}))
    store.setSelectedOption('v_model',
        this.models.find(
            i => i.slug === location.pathname.replace(/\/$/, '').split('/').pop()
        )
    )

    // this.initLD()
    const [color] = this.filters.v_color
    store.setSelectedOption('v_color', color)
  },
  mounted () {
    window.onresize = () => {
      setTimeout(() => {
        this.windowWidth = window.innerWidth;
      }, 300);
    }
  }
}
</script>

<style lang="scss">
.filter {
  &__buttons {
    display: flex;
    align-content: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: FuturaPT, sans-serif;
    padding: 12px 24px;
    border-radius: 1.5rem;
    background-color: #333;
    border: none;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;
    font-weight: 600;

    &--default {
      width: 5.25rem;
    }

    &--active {
      background: #ffd600;
      color: #0d0d0d;
    }

    &-clear {
      display: flex;
      margin-left: 0.5rem;
      width: 0.625rem;
      min-width: 0.625rem;
      height: 0.625rem;
    }
  }

  @media screen and (max-width: 60em) {
    overflow-x: auto;

    .filter {
      &__wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        transition: transform ease-in-out 0.25s;
        border-radius: 8px;
        box-shadow: 0 -8px 40px 0 #0d0d0d;
        background-color: #181818;
        max-width: 100vw!important;
        display: none;
        z-index: 1000;
        margin-bottom: 0;
        padding: 1rem 1rem 1.5rem;
        height: 20.5rem;

        &--show {
          display: block;
        }
      }

      &__overlay {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: calc(-100vh + 0.5rem);
        height: 100vh;
        background: #181818;
        opacity: .5;
      }

      &__options {
        overflow-y: auto;
        max-height: 14rem;
      }
    }
  }
}
</style>
